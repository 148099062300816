import { colors } from '@mui/material';

const white = '#FFFFFF';

export default {
  primary: {
    dark: '#5a0002',
    main: '#a40606',
    light: '#a40606',
    contrastText: white
  },
  secondary: {
    contrastText: white,
    dark: '#7A181A',
    main: '#a40606',
    light: '#a40606'
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#00112D',
    secondary: '#7B889D',
    subtitle: '#7E89A1',
    link: colors.blue[600]
  },
  default: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
    backgroundColor: '#f5f5f5'
  },
  post: {
    title: '#1A1A1A',
    subtitle: '#111111',
    body: '#3D3D3D'
  },
  link: {
    main: '#1E88E5',
    dark: '#00008B'
  },
  white: '#FFFFFF',
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  gray: {
    dark: '#a4a99f',
    main: '#f4f6f8',
    secondary: '#eceff1',
    light: '#eeeeee',
    superLight: '#fafafa',
    contrastText: '#263238',
    icon: '#546e7a',
    border: '#cfd8dc'
  },
  black: {
    main: '#263238',
    dark: '#000000',
    secondary: '#111827'
  },
  divider: colors.grey[200]
};
