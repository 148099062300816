import React from 'react';
import { EditRounded, OnlinePredictionRounded } from '@mui/icons-material';
import { editIconProps, onlineIconProps } from './PostTable/sxProps';
import {
  STATUS_DRAFT,
  STATUS_LIVE,
  MARKET_STANDARDS_REPORT_TYPE,
  MANAGER_REPORT_TYPE,
  DEAL_UPDATE_TYPE,
  TOPIC_REPORT_TYPE,
  SECONDARY_TYPE,
  SCORECARD_TYPE,
  FIERY_FIND_TYPE,
  EXECUTED_TYPE,
  PRELIMINARY_TYPE,
  OTHER_TYPE
} from '../../../utils/constants/stringConstants';

export const columnToFilterMap = {
  type: 'typeList',
  executionDateFrom: 'executionDateFrom',
  executionDateTo: 'executionDateTo'
};

export const typeColorMap = new Map([
  [TOPIC_REPORT_TYPE, '#7F1D1D'],
  [SCORECARD_TYPE, '#78350F'],
  [MARKET_STANDARDS_REPORT_TYPE, '#064E3B'],
  [MANAGER_REPORT_TYPE, '#1E3A8A'],
  [SECONDARY_TYPE, '#187D83'],
  [DEAL_UPDATE_TYPE, '#7A812E'],
  [FIERY_FIND_TYPE, '#4C1D95'],
  [PRELIMINARY_TYPE, '#831843'],
  [EXECUTED_TYPE, '#246913'],
  [OTHER_TYPE, '#111827']
]);

export const typeBackgroundColorMap = new Map([
  [TOPIC_REPORT_TYPE, '#FEF2F2'],
  [SCORECARD_TYPE, '#FFFBEB'],
  [MARKET_STANDARDS_REPORT_TYPE, '#ECFDF5'],
  [MANAGER_REPORT_TYPE, '#EFF6FF'],
  [SECONDARY_TYPE, '#F2FCFD'],
  [DEAL_UPDATE_TYPE, '#FFFDEE'],
  [FIERY_FIND_TYPE, '#F5F3FF'],
  [PRELIMINARY_TYPE, '#FDF2F8'],
  [EXECUTED_TYPE, '#EFFFEB'],
  [OTHER_TYPE, '#F9FAFB']
]);

export const circleColorMap = new Map([
  [TOPIC_REPORT_TYPE, '#DC2626'],
  [SCORECARD_TYPE, '#D97706'],
  [MARKET_STANDARDS_REPORT_TYPE, '#059669'],
  [MANAGER_REPORT_TYPE, '#2563EB'],
  [SECONDARY_TYPE, '#27DBDB'],
  [DEAL_UPDATE_TYPE, '#F0E93D'],
  [FIERY_FIND_TYPE, '#7C3AED'],
  [PRELIMINARY_TYPE, '#DB2777'],
  [EXECUTED_TYPE, '#246913'],
  [OTHER_TYPE, '#4B5563']
]);

export const postTypesMap = new Map([
  [TOPIC_REPORT_TYPE, 1],
  [SCORECARD_TYPE, 2],
  [MARKET_STANDARDS_REPORT_TYPE, 3],
  [MANAGER_REPORT_TYPE, 4],
  [SECONDARY_TYPE, 5],
  [DEAL_UPDATE_TYPE, 6],
  [FIERY_FIND_TYPE, 7],
  [PRELIMINARY_TYPE, 8],
  [EXECUTED_TYPE, 9],
  [OTHER_TYPE, 10]
]);

export const initFilters = {
  postTypes: [],
  tags: [],
  products: [],
  status: [STATUS_LIVE],
  createdFrom: '',
  createdTo: ''
};

export const statusTitleMap = new Map([
  [
    STATUS_LIVE,
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      Show live posts <OnlinePredictionRounded sx={onlineIconProps} />
    </div>
  ],
  [
    STATUS_DRAFT,
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      Show draft posts <EditRounded sx={editIconProps} />
    </div>
  ]
]);

export const ITEM_PER_PAGE = 15;

export const POSTED_DATE = 'postedDate';
