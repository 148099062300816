import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Input,
  LinearProgress,
  Modal,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { usePrevious } from '@react-pdf-viewer/core';
import useStyles from './styles';
import modalStyles from '../../assets/scss/modalWindow';
import { searchTypes } from '../../utils/constants/listsConstants';
import {
  INVALID_DATE,
  SEARCH_ALL,
  SEARCH_DEALS,
  SEARCH_PDF_CONTENT,
  SEARCH_REPORTS,
  SEARCH_TOPICS
} from '../../utils/constants/stringConstants';
import { getFormattedDate, onPressEnter } from '../../utils/utils';
import {
  searchDealsInfo,
  searchDocumentContent,
  searchReportsInfo,
  searchTopicsInfo
} from '../../services/api/search';
import { showAlert } from '../../redux/actions';
import { trimValue } from '../../utils/common';
import ReportCard from './ReportCard';
import ReportContentCard from './ReportContentCard';
import DocumentContentCard from './DocumentContentCard';
import DealCard from './DealCard';
import TopicCard from './TopicCard';
import ElasticSearchFilter from './ElasticSearchFilter/ElasticSearchFilter';
import ElasticContext from './context/elastic.context';
import * as actionsCreator from './context/elastic.actions';
import TablePaginationMainActions from '../TablePaginationActions/TablePaginationMainActions';

const ElasticSearchModal = ({ open, onClose }) => {
  const classes = useStyles();
  const modalClasses = modalStyles();
  const {
    state: {
      searchResult,
      availableFilters,
      filterValues,
      keyCustomFields,
      loading,
      pagination,
      showFilterByProduct
    },
    dispatch
  } = useContext(ElasticContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [searchType, setSearchType] = useState(SEARCH_PDF_CONTENT);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchApplied, setSearchApplied] = useState(false);
  const [updateUniqueValues, setUpdateUniqueValues] = useState(true);
  const [cancelSources, setCancelSources] = useState([]);
  const dispatchRedux = useDispatch();
  const [formState, setFormState] = useState({
    filterValues: {
      products: [],
      dealTypes: [],
      deals: [],
      currencies: [],
      reportTypes: [],
      customFieldMap: new Map(),
      selectedDates: [null, null]
    },
    currentPage: {
      dealsInfo: 1,
      documentContent: 1,
      topicsInfo: 1,
      reportsInfo: 1
    }
  });
  const prevCurrentPage = usePrevious(formState.currentPage);
  const prevSearchType = usePrevious(searchType);
  const documentsRef = useRef(null);
  const dealsRef = useRef(null);
  const topicsRef = useRef(null);
  const reportsRef = useRef(null);

  const cancelRequests = () => {
    cancelSources.forEach((source) => source.cancel());
    setCancelSources([]);
  };

  const getRefByType = (cardType) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return dealsRef;
      case SEARCH_PDF_CONTENT:
        return documentsRef;
      case SEARCH_TOPICS:
        return topicsRef;
      case SEARCH_REPORTS:
        return reportsRef;
      default:
        return null;
    }
  };

  const clearFormState = () => {
    setFormState(() => ({
      filterValues: {
        products: [],
        dealTypes: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map(),
        selectedDates: [null, null]
      },
      currentPage: {
        dealsInfo: 1,
        documentContent: 1,
        topicsInfo: 1,
        reportsInfo: 1
      }
    }));
    setUpdateUniqueValues(true);
  };

  const scrollToBlock = (blockRef) => {
    if (!!blockRef && blockRef.current) {
      blockRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleClose = () => {
    cancelRequests();
    dispatch(actionsCreator.clearState());
    clearFormState();
    setSearchValue('');
    setSearchPhrase('');
    dispatch(actionsCreator.setLoading(false));
    setSearchApplied(false);
    onClose();
  };

  const countOfAppliedFilters = useMemo(() => {
    const filters = [
      filterValues.products,
      filterValues.currencies,
      filterValues.dealTypes,
      filterValues.deals,
      filterValues.reportTypes,
      filterValues.selectedDates && !filterValues.selectedDates.every((date) => date === null)
        ? filterValues.selectedDates
        : [],
      ...Array.from(filterValues.customFieldMap.values())
    ];
    return filters.reduce((count, filter) => count + (filter.length ? 1 : 0), 0);
  }, [filterValues]);

  const updateNavigateUrl = (searchTypeParam, searchValueParam) => {
    queryParams.set('globalSearchModal', 'true');
    queryParams.set('searchType', searchTypeParam);
    queryParams.set('searchValue', searchValueParam);
    navigate({
      search: queryParams.toString()
    });
  };

  const handleChange = ({ target }) => {
    cancelRequests();
    setSearchValue(target.value);
    dispatch(actionsCreator.setLoading(false));
  };

  const restoreFilter = () => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      filterValues
    }));
  };

  const updateFilterValues = (name, value, filedId) => {
    if (name === 'customFieldMap') {
      setFormState((prevFormState) => {
        const updatedCustomFieldMap = new Map(prevFormState.filterValues.customFieldMap);
        updatedCustomFieldMap.set(filedId, value);

        return {
          ...prevFormState,
          filterValues: {
            ...prevFormState.filterValues,
            customFieldMap: updatedCustomFieldMap
          }
        };
      });
    } else {
      setFormState((prevFormState) => ({
        ...prevFormState,
        filterValues: {
          ...prevFormState.filterValues,
          [name]: value
        }
      }));
    }
  };

  const getRequestParams = () => {
    const customFieldsMap = new Map(
      [...formState.filterValues.customFieldMap]
        .map(([key, values]) => {
          const keyField = keyCustomFields.get(key);
          if (keyField.type !== 'Date') {
            const fieldValues = values.map((item) => item.id);
            if (fieldValues.length > 0) {
              return [
                key,
                {
                  field: keyField.name,
                  operator: 'isAnyOf',
                  value: fieldValues
                }
              ];
            }
            return null;
          }
          const dateFrom = getFormattedDate(values[0]);
          const dateTo = getFormattedDate(values[1]);
          if (dateFrom !== INVALID_DATE && dateTo !== INVALID_DATE) {
            return [
              key,
              {
                field: keyField.name,
                operator: 'between',
                value: [dateFrom, dateTo]
              }
            ];
          }
          return null;
        })
        .filter(Boolean)
    );
    const customFieldsObject = Object.fromEntries(customFieldsMap);
    const dateFrom = getFormattedDate(formState.filterValues.selectedDates[0]);
    const dateTo = getFormattedDate(formState.filterValues.selectedDates[1]);
    return {
      search: searchPhrase,
      productIds: formState.filterValues.products?.map((item) => item.id) || [],
      dealTypeIds: formState.filterValues.dealTypes?.map((item) => item.id) || [],
      dealIds: formState.filterValues.deals?.map((item) => item.id) || [],
      currencies: formState.filterValues.currencies?.map((item) => item.id) || [],
      reportTypes: formState.filterValues.reportTypes?.map((item) => item.id) || [],
      dateFrom: dateFrom !== INVALID_DATE ? dateFrom : '',
      dateTo: dateTo !== INVALID_DATE ? dateTo : '',
      ...(customFieldsMap.size > 0 && { customFields: customFieldsObject })
    };
  };

  const getPaginationSettingsName = (cardType) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return 'dealsInfo';
      case SEARCH_PDF_CONTENT:
        return 'documentContent';
      case SEARCH_TOPICS:
        return 'topicsInfo';
      case SEARCH_REPORTS:
        return 'reportsInfo';
      default:
        return <div />;
    }
  };

  const searchByType = async (searchMethod, cardType, resetValues) => {
    if (!searchPhrase || searchPhrase.length < 3) {
      return;
    }
    const paginationSettingsName = getPaginationSettingsName(cardType);
    setSearchApplied(true);
    dispatch(actionsCreator.getSearchResult());
    dispatch(actionsCreator.updateFilters(formState.filterValues));
    try {
      const source = axios.CancelToken.source();
      setCancelSources((prevSources) => [...prevSources, source]);
      const data = await searchMethod(
        SEARCH_ALL === cardType ? {} : getRequestParams(),
        { page: formState.currentPage[paginationSettingsName] },
        source.token
      );
      const result = data?.result.map((item) => ({
        ...item,
        cardType,
        ...(cardType !== SEARCH_TOPICS && { values: item.values })
      }));

      data.filterUniqueValues.currencies = data.filterUniqueValues?.currencies?.map((name) => ({
        id: name,
        name
      }));
      data.filterUniqueValues.reportTypes = data.filterUniqueValues?.reportTypes?.map((name) => ({
        id: name,
        name
      }));
      data.filterUniqueValues.customFieldMap = new Map(
        Object.entries(data.filterUniqueValues?.customFieldMap || {})
      );
      dispatch(
        actionsCreator.getSearchResultSuccess(
          result,
          resetValues,
          data.filterUniqueValues,
          getPaginationSettingsName(cardType),
          data.pageCounter,
          data.itemCounter,
          updateUniqueValues
        )
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatchRedux(
          showAlert({ isShown: true, type: 'error', message: error.response?.data?.message })
        );
        dispatch(actionsCreator.setLoading(false));
      }
    }
  };

  const handleSearchTypeChange = ({ target }) => {
    cancelRequests();
    setSearchApplied(false);
    dispatch(actionsCreator.clearState());
    clearFormState();
    setSearchType(target.value);
  };

  const getSearchMethodByCardType = (cardType) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return searchDealsInfo;
      case SEARCH_PDF_CONTENT:
        return searchDocumentContent;
      case SEARCH_TOPICS:
        return searchTopicsInfo;
      case SEARCH_REPORTS:
        return searchReportsInfo;
      default:
        return null;
    }
  };

  const performSearch = async () => {
    if (!!searchType) {
      updateNavigateUrl(trimValue(searchType), trimValue(searchPhrase));
      dispatch(actionsCreator.setLoading(true));
      if (SEARCH_ALL === searchType) {
        dispatch(actionsCreator.clearSearchResult());
        await searchByType(
          getSearchMethodByCardType(SEARCH_PDF_CONTENT),
          SEARCH_PDF_CONTENT,
          false
        );
        await searchByType(getSearchMethodByCardType(SEARCH_DEALS), SEARCH_DEALS, false);
        await searchByType(getSearchMethodByCardType(SEARCH_TOPICS), SEARCH_TOPICS, false);
        await searchByType(getSearchMethodByCardType(SEARCH_REPORTS), SEARCH_REPORTS, false);
      } else {
        await searchByType(getSearchMethodByCardType(searchType), searchType, true);
      }
      dispatch(actionsCreator.setLoading(false));
      setUpdateUniqueValues(false);
    }
  };

  const applyFilter = () => {
    scrollToBlock(getRefByType(searchType));
    dispatch(actionsCreator.clearSearchResult());
    performSearch();
  };

  useEffect(() => {
    if (searchPhrase.length) {
      cancelRequests();
      dispatch(actionsCreator.clearState());
      clearFormState();
    }
  }, [searchPhrase]);

  useEffect(() => {
    if (searchPhrase.length) {
      cancelRequests();
      if (SEARCH_ALL === searchType && prevSearchType === searchType) {
        let cardType;
        if (prevCurrentPage.dealsInfo !== formState.currentPage.dealsInfo) {
          cardType = SEARCH_DEALS;
        } else if (prevCurrentPage.documentContent !== formState.currentPage.documentContent) {
          cardType = SEARCH_PDF_CONTENT;
        } else if (prevCurrentPage.reportsInfo !== formState.currentPage.reportsInfo) {
          cardType = SEARCH_REPORTS;
        } else if (prevCurrentPage.topicsInfo !== formState.currentPage.topicsInfo) {
          cardType = SEARCH_TOPICS;
        }
        if (!!cardType) {
          scrollToBlock(getRefByType(cardType));
          dispatch(actionsCreator.removeSearchResultByType(cardType));
          searchByType(getSearchMethodByCardType(cardType), cardType, false);
        } else {
          scrollToBlock(getRefByType(searchType));
          dispatch(actionsCreator.clearSearchResult());
          performSearch();
        }
      } else {
        scrollToBlock(getRefByType(searchType));
        dispatch(actionsCreator.clearSearchResult());
        performSearch();
      }
    }
  }, [formState.currentPage]);

  useEffect(() => {
    if (open) {
      const searchParams = new URLSearchParams(location.search);
      const searchValueParam = searchParams.get('searchValue');
      if (!!searchValueParam) {
        setSearchValue(trimValue(searchValueParam));
        setSearchPhrase(trimValue(searchValueParam));
      }
      const searchTypeParam = searchParams.get('searchType');
      if (!!searchTypeParam) {
        setSearchType(searchTypeParam);
      }
    } else {
      setSearchValue('');
      setSearchPhrase('');
      dispatch(actionsCreator.setLoading(false));
      setSearchType(SEARCH_PDF_CONTENT);
      dispatch(actionsCreator.clearState());
      clearFormState();
    }
  }, [open]);

  const renderCard = (cardType, data) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return <DealCard data={data} searchValue={searchPhrase} />;
      case SEARCH_PDF_CONTENT:
        return <DocumentContentCard data={data} searchValue={searchPhrase} />;
      case SEARCH_TOPICS:
        return <TopicCard data={data} searchValue={searchPhrase} />;
      case SEARCH_REPORTS:
        return data.pdfContent ? (
          <ReportContentCard data={data} searchValue={searchPhrase} />
        ) : (
          <ReportCard data={data} searchValue={searchPhrase} />
        );
      default:
        return <div />;
    }
  };

  const handleChangePage = (event, newPage, cardType) => {
    const paginationName = getPaginationSettingsName(cardType);
    setFormState((prevFormState) => ({
      ...prevFormState,
      currentPage: {
        ...prevFormState.currentPage,
        [paginationName]: newPage
      }
    }));
    dispatch(actionsCreator.updatePaginationPage(paginationName, newPage));
  };

  const getContentBlock = (cardType, data) => {
    const paginationSettingsName = getPaginationSettingsName(cardType);

    return (
      <div
        className={clsx(
          classes.contentWithPagination,
          searchType === SEARCH_ALL ? classes.contentAllWithNoHeight : ''
        )}
        ref={searchType !== SEARCH_ALL ? getRefByType(cardType) : null}
      >
        {!loading && searchApplied && !searchResult.length && (
          <span className={classes.emptyResult}>No results</span>
        )}
        <div
          className={
            cardType === SEARCH_PDF_CONTENT || cardType === SEARCH_REPORTS
              ? classes.cardsList
              : classes.cardsTable
          }
        >
          {data.map((data) => renderCard(cardType, data))}
        </div>
        {!!searchResult.length && (
          <div className={classes.pagination}>
            <TablePagination
              disabled={loading}
              className={classes.paginationBlock}
              rowsPerPageOptions={[]}
              component="div"
              labelDisplayedRows={({ page }) =>
                `Page ${page} of ${pagination[paginationSettingsName].pageCounter}`
              }
              count={pagination[paginationSettingsName].itemCounter || 0}
              rowsPerPage={pagination[paginationSettingsName].itemPerPage}
              page={pagination[paginationSettingsName].page}
              onPageChange={(event, newPage) => handleChangePage(event, newPage, cardType)}
              ActionsComponent={TablePaginationMainActions}
            />
          </div>
        )}
      </div>
    );
  };

  const getCountOfResult = (cardType) => {
    const paginationSettingsName = getPaginationSettingsName(cardType);
    if (cardType !== SEARCH_ALL) {
      return pagination[paginationSettingsName].itemCounter || 0;
    }
    return (
      pagination.dealsInfo.itemCounter +
      pagination.documentContent.itemCounter +
      pagination.topicsInfo.itemCounter +
      pagination.reportsInfo.itemCounter
    );
  };

  const getContentBlockWithHeader = (cardType) => (
    <div className={classes.contentWithHeader} ref={getRefByType(cardType)}>
      <div>{`${cardType} / ${getCountOfResult(cardType)}`}</div>
      {getContentBlock(
        cardType,
        searchResult.filter((item) => item.cardType === cardType)
      )}
    </div>
  );

  const getContentBlockForAll = () => (
    <div className={classes.contentAll}>
      {getContentBlockWithHeader(SEARCH_PDF_CONTENT)}
      {getContentBlockWithHeader(SEARCH_DEALS)}
      {getContentBlockWithHeader(SEARCH_TOPICS)}
      {getContentBlockWithHeader(SEARCH_REPORTS)}
    </div>
  );

  const applyNewSearch = () => {
    if (trimValue(searchValue) !== searchPhrase) {
      setSearchPhrase(trimValue(searchValue));
      setUpdateUniqueValues(true);
    }
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <div className={clsx(modalClasses.root, classes.modalForm)}>
        <div className={classes.header}>
          <CloseOutlined
            className={classes.closeIcon}
            sx={{ height: '20px', width: '20px' }}
            onClick={handleClose}
          />
          <div className={classes.searchWithFilter}>
            <Input
              name="search"
              value={searchValue}
              placeholder="Search Dealscribe"
              className={classes.searchField}
              onChange={handleChange}
              onKeyDown={onPressEnter(applyNewSearch)}
              onBlur={applyNewSearch}
            />
            <div className={classes.buttonGroup}>
              <ToggleButtonGroup
                disabled={loading}
                className={classes.searchTypeGroup}
                color="primary"
                value={searchType}
                exclusive
                onChange={handleSearchTypeChange}
                handleSearchTypeChange
                aria-label="Platform"
              >
                {searchTypes.map((type) => (
                  <ToggleButton
                    name="searchType"
                    className={clsx(
                      classes.searchTypeItem,
                      searchType === type && classes.searchTypeSelected
                    )}
                    value={type}
                  >
                    {type === searchType && searchApplied && !loading
                      ? `${type} / ${getCountOfResult(searchType)}`
                      : type}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {searchType !== SEARCH_ALL && (
                <ElasticSearchFilter
                  searchType={searchType}
                  loading={loading}
                  availableFilters={availableFilters}
                  filterValues={formState.filterValues}
                  updateFilterValues={updateFilterValues}
                  performSearch={applyFilter}
                  keyCustomFields={keyCustomFields}
                  restoreFilter={restoreFilter}
                  countOfAppliedFilters={countOfAppliedFilters}
                  clearFormState={clearFormState}
                  showFilterByProduct={showFilterByProduct}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {loading && <LinearProgress />}
            {searchType !== SEARCH_ALL && getContentBlock(searchType, searchResult)}
            {searchType === SEARCH_ALL && searchResult.length > 0 && getContentBlockForAll()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ElasticSearchModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ElasticSearchModal.defaultProps = {
  open: false
};

export default ElasticSearchModal;
