import React from 'react';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';

const contactEmail = 'contact@dealscribe.com';

const ContactEmailItem = ({ classes }) => (
  <Link href={`mailto:${contactEmail}`} className={classes.contactEmail}>
    {contactEmail}
  </Link>
);

ContactEmailItem.propTypes = {
  classes: PropTypes.object
};

export default ContactEmailItem;
