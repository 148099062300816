import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { CommentOutlined, Flag } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Score from 'src/components/Score/Score';
import { DISPLAYED_TOPIC_ANSWER_LENGTH } from 'src/utils/constants/numericConstants';
import useStyles from './styles';

export const Topic = ({ topic }) => {
  const classes = useStyles();

  const flexibleTopicAnswer = () => {
    if (topic.answer) {
      return topic?.answer?.length < DISPLAYED_TOPIC_ANSWER_LENGTH
        ? topic?.answer
        : `${topic?.answer?.slice(0, DISPLAYED_TOPIC_ANSWER_LENGTH)}...`;
    }
    return '';
  };
  return (
    <Grid container direction="row" justifyContent="space-evenly">
      <Grid item className={classes.answerWrap}>
        {flexibleTopicAnswer()}
        {topic?.answer?.length > DISPLAYED_TOPIC_ANSWER_LENGTH && (
          <Tooltip title={topic?.answer}>
            <span className={classes.linkBtn}> View all </span>
          </Tooltip>
        )}
      </Grid>
      <Grid item md={2} style={{ marginLeft: '10px' }}>
        <Box>
          {topic.isUnusual && (
            <Tooltip
              disableInteractive
              title="Answer requires special attention"
              aria-label="answer"
            >
              <Flag className={classes.flagIcon} color="secondary" />
            </Tooltip>
          )}

          {topic.notes && (
            <Tooltip disableInteractive title={topic.notes} aria-label="answer">
              <CommentOutlined className={classes.noteIcon} />
            </Tooltip>
          )}
        </Box>
        <Box>
          <Grid>{topic?.score !== null && <Score type="topicScore" mark={topic.score} />}</Grid>

          <Grid className={classes.esgScore}>
            {topic?.esgScore !== null && <Score type="esgScore" mark={topic.esgScore} />}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

Topic.propTypes = {
  topic: PropTypes.object
};
