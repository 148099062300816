import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Button, Grid } from '@mui/material';
import { showAlert } from 'src/redux/actions';
import { getAboutUsDescription, putAboutUsDescription } from 'src/services/api/aboutUs';
import { useStyles } from './styles';
import { ADMIN_ROLE } from '../../../../utils/constants/stringConstants';
import EditAboutDealscribeModal from './EditAboutDealscribeModal/EditAboutDealscribeModal';
import UnsavedMessageModal from '../../../../components/UnsavedMessageModal';

export const AboutDealscribe = ({ commonClasses }) => {
  const classes = useStyles();
  const userRole = useSelector((state) => state.session.user.role);
  const dispatchRedux = useDispatch();
  const [aboutUsDescription, setAboutUsDescription] = useState(null);
  const [openModalConfirmUnsaved, setOpenModalConfirmUnsaved] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const loadAboutUsDescription = async () => {
    try {
      const data = await getAboutUsDescription();
      setAboutUsDescription(data.text);
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error.response.data.message })
      );
    }
  };

  const submit = async (newDescription) => {
    try {
      await putAboutUsDescription(newDescription);
      setAboutUsDescription(newDescription);
      setEditModalOpen(false);
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error.response.data.message })
      );
    }
  };

  const closeUnsavedData = () => {
    setOpenModalConfirmUnsaved(false);
    setEditModalOpen(false);
  };

  useEffect(() => {
    loadAboutUsDescription();
  }, []);

  return (
    <Grid className={commonClasses.headerWrapper}>
      <UnsavedMessageModal
        open={openModalConfirmUnsaved}
        onClose={closeUnsavedData}
        onCancel={() => setOpenModalConfirmUnsaved(false)}
      />
      <EditAboutDealscribeModal
        open={editModalOpen}
        data={aboutUsDescription}
        submit={submit}
        onClose={(isChanged) =>
          isChanged ? setOpenModalConfirmUnsaved(true) : setEditModalOpen(false)
        }
      />
      <Grid className={commonClasses.badgeWrapper}>
        <Typography className={commonClasses.badgeHeader}>About us</Typography>
        {userRole === ADMIN_ROLE && (
          <Button
            variant="contained"
            className={commonClasses.editButton}
            onClick={() => setEditModalOpen(true)}
          >
            Edit
          </Button>
        )}
      </Grid>
      <Typography className={commonClasses.header}>
        Meet the <span className={commonClasses.headerBold}>Dealscribe</span> <br />
        team
      </Typography>
      <Typography align="justify" className={classes.text}>
        {aboutUsDescription}
      </Typography>
    </Grid>
  );
};

AboutDealscribe.propTypes = {
  commonClasses: PropTypes.object
};
