import React from 'react';
import { getProductAllowed } from 'src/services/api/product';
import { showAlert } from 'src/redux/actions';
import { BadgeForProduct } from 'src/components/BadgeForProduct/BadgeForProduct';
import { DEFAULT_STORE } from '../../utils/userLocalStorageSettings';
import * as actionsCreator from './context/deal.actions';
import { getPortfolio, getSharedPortfolio } from '../../services/api/portfolio';

export const getProductsAllowed = async (dispatch, dispatchRedux, updateTableSettings) => {
  dispatch(actionsCreator.loadProducts());
  try {
    const data = await getProductAllowed();
    if (updateTableSettings && !data.length) {
      await updateTableSettings(data);
    }
    dispatch(actionsCreator.loadProductsSuccess(data));
  } catch (error) {
    dispatchRedux(
      showAlert({ isShown: true, type: 'error', message: error.response.data.message })
    );
  }
};

export const getPortfolios = async (dispatch, dispatchRedux) => {
  try {
    dispatch(actionsCreator.getPortfolioList());
    const data = await getPortfolio();
    dispatch(actionsCreator.getPortfolioListSuccess(data));
  } catch (err) {
    dispatchRedux(
      showAlert({
        isShown: true,
        type: 'error',
        message: err.response?.data?.message || err.message
      })
    );
  }
};

export const getSharedPortfolios = async (dispatch, dispatchRedux) => {
  try {
    dispatch(actionsCreator.getSharedPortfolioList());
    const data = await getSharedPortfolio();
    dispatch(actionsCreator.getSharedPortfolioListSuccess(data));
  } catch (err) {
    dispatchRedux(
      showAlert({
        isShown: true,
        type: 'error',
        message: err.response?.data?.message || err?.message
      })
    );
  }
};

export const getStoreKey = (selectedProducts) =>
  selectedProducts.length === 1 ? selectedProducts[0].id : DEFAULT_STORE;

export const getStore = ({ columnsStore }, selectedProducts) =>
  columnsStore[getStoreKey(selectedProducts)];

export const getStoreTableColumns = ({ columnsStore }, selectedProducts) =>
  columnsStore[getStoreKey(selectedProducts)]?.tableColumns;

export const getUpdatedTableSettings = (tableSettings, products) => {
  const updatedTableSettings = { ...tableSettings };
  const defaultStore = updatedTableSettings.columnsStore[DEFAULT_STORE];
  const storeKeys = Object.keys(tableSettings.columnsStore);
  products.forEach((product) => {
    const productId = `${product.id}`;
    const index = storeKeys.indexOf(productId);
    if (index === -1) {
      updatedTableSettings.columnsStore[productId] = defaultStore;
    } else {
      storeKeys.splice(index, 1);
    }
  });
  storeKeys.forEach((storedProduct) => {
    if (storedProduct !== DEFAULT_STORE) {
      delete updatedTableSettings.columnsStore[storedProduct];
    }
  });
  return updatedTableSettings;
};

export const alertBody = (requestState, successfulMessage, errorMessage) =>
  requestState.status === 200
    ? { isShown: true, type: 'success', message: successfulMessage }
    : {
        isShown: true,
        type: 'error',
        message: requestState.message || errorMessage
      };

export const getPortfolioItem = (props, option, selected, showBadge) => (
  <li {...props}>
    <div
      style={{
        display: 'flex',
        width: '100vw',
        justifyContent: 'space-beetwen',
        alignItems: 'center'
      }}
    >
      <span style={{ flex: '1' }}>{option.name}</span>
      {option.product && (
        <BadgeForProduct
          option={option}
          width={50}
          fontSize={12}
          padding="4px 8px"
          showBadge={showBadge}
        />
      )}
    </div>
  </li>
);

export const updatePortfolioStatus = (items, enabledDealIds, idField) =>
  items.map((item) => ({
    ...item,
    enableShowPortfolioStatus:
      enabledDealIds.length > 0 ? enabledDealIds.includes(item[idField]) : true
  }));
