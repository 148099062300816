import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'space-between'
  },
  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cross: {
    cursor: 'pointer',
    '&:hover': {
      color: '#546E7A'
    }
  },
  closeButton: {
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  }
}));

export default useStyles;
