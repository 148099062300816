import React, { Suspense, useReducer } from 'react';
import { Paper, Divider, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getOperationsTabs } from 'src/utils/tabsUtils';
import OperationsPageHeader from './OperationsPageHeader';
import operationsContext from './context/operations.context';
import OperationsReducer, { initialState } from './context/operations.reducer';
import useStyles from './styles';

function OperationsPage() {
  const classes = useStyles();
  const [state, dispatch] = useReducer(OperationsReducer, initialState);
  const userRole = useSelector((reduxState) => reduxState.session.user.role);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <operationsContext.Provider value={{ state, dispatch }}>
      <Paper className={classes.contentContainer} elevation={0}>
        <OperationsPageHeader tabs={getOperationsTabs(userRole)} />
        <Divider />
        <Suspense fallback={<LinearProgress />}>
          <Outlet />
        </Suspense>
      </Paper>
    </operationsContext.Provider>
  );
}

export default OperationsPage;
