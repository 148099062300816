import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  text: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    width: '100%',
    boxSizing: 'border-box',
    color: '#4B5563'
  }
}));
