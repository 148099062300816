import axios from 'axios';

export const searchDealsInfo = async (body, params, cancelToken) => {
  const { data } = await axios.post('/search/deal-info', body, {
    cancelToken,
    params
  });
  return data;
};

export const searchDocumentContent = async (body, params, cancelToken) => {
  const { data } = await axios.post('/search/document-content', body, {
    cancelToken,
    params
  });
  return data;
};

export const searchReportsInfo = async (body, params, cancelToken) => {
  const { data } = await axios.post('/search/report-info', body, {
    cancelToken,
    params
  });
  return data;
};

export const searchTopicsInfo = async (body, params, cancelToken) => {
  const { data } = await axios.post('/search/topic-info', body, {
    cancelToken,
    params
  });
  return data;
};

export const getDocumentContentById = async (id) => {
  const { data } = await axios.get(`/search/document-content/${id}`);
  return data;
};

export const getReportContentById = async (id) => {
  const { data } = await axios.get(`/search/report-content/${id}`);
  return data;
};
