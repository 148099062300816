import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  FormControl
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from 'src/assets/scss/modalWindow';
import { ADMIN_TOPICS_SEARCH } from '../../../../utils/constants/stringConstants';

function SearchTopicModal({ open, onClose, className, topics, downloadTopic }) {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    values: {
      topicName: '',
      topicId: '',
      isDownloadable: false
    }
  });

  useEffect(() => {
    setFormState({
      ...formState,
      values: {
        topicName: '',
        topicId: '',
        isDownloadable: false
      }
    });
  }, [open]);

  const onCloseHandler = () => {
    onClose(true);
  };

  const download = () => {
    onCloseHandler();
    downloadTopic(formState.values.topicId);
  };

  const topicsFilterOptions = createFilterOptions({
    stringify: (option) => option.topicName
  });

  const topicChange = (event, selectedTopic) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        topicName: selectedTopic && selectedTopic.topicName ? selectedTopic.topicName : '',
        topicId: selectedTopic && selectedTopic.id ? selectedTopic.id : '',
        isDownloadable: selectedTopic && selectedTopic.topicName.length > 0
      }
    }));
  };

  if (!open) {
    return null;
  }

  const headerModal = (
    <div className={classes.headerModal}>
      <span>{ADMIN_TOPICS_SEARCH}</span>
      <CloseIcon className={classes.cross} onClick={onCloseHandler} />
    </div>
  );

  // eslint-disable-next-line no-shadow
  const setArrFromTopicsList = (topics) => {
    const options = [];
    // eslint-disable-next-line array-callback-return
    topics.map((item) => {
      item.topics.map((topic) =>
        options.push({
          category: item.category,
          categoryPosition: item.categoryPosition,
          ...topic
        })
      );
    });

    return options;
  };

  return (
    <Modal onClose={onCloseHandler} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent>
          <FormControl variant="outlined" className={classes.formControl} fullWidth margin="normal">
            <Autocomplete
              options={setArrFromTopicsList(topics)}
              filterOptions={topicsFilterOptions}
              getOptionLabel={(option) => option.topicName}
              onChange={topicChange}
              renderInput={(params) => <TextField {...params} label="Topic" variant="outlined" />}
            />
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button color="black" onClick={onCloseHandler}>
            CLOSE
          </Button>
          <Button
            disabled={!formState.values.isDownloadable}
            color="primary"
            variant="contained"
            onClick={download}
          >
            DOWNLOAD
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

SearchTopicModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  topics: PropTypes.any,
  downloadTopic: PropTypes.func
};

SearchTopicModal.defaultProps = {
  open: false
};

export default SearchTopicModal;
