import * as actionsCreator from '../context/operations.actions';
import { getDealType, getGroupedDealTypes } from '../../../services/api/dealType';
import { showAlert } from '../../../redux/actions';
import { getProducts } from '../utils';

export const getDealTypes = async (dispatch, dispatchRedux) => {
  dispatch(actionsCreator.loadDealTypes());
  try {
    const data = await getDealType();
    dispatch(actionsCreator.loadDealTypesSuccess(data));
  } catch (error) {
    dispatchRedux(
      showAlert({ isShown: true, type: 'error', message: error.response.data.message })
    );
  }
};

export const getGroupedByProductDealTypes = async (dispatch, dispatchRedux) => {
  await getProducts(dispatch, dispatchRedux);

  dispatch(actionsCreator.loadGroupedByProductDealTypes());
  try {
    const data = await getGroupedDealTypes();
    dispatch(actionsCreator.loadGroupedByProductDealTypesSuccess(data));
  } catch (error) {
    dispatchRedux(
      showAlert({ isShown: true, type: 'error', message: error.response.data.message })
    );
  }
};
