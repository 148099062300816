import React, { useReducer, Suspense } from 'react';
import { LinearProgress, Grid, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TabsHeader from './TabsHeader/TabsHeader';
import DealReducer, { initialState } from '../context/deal.reducer';
import DealContext from '../context/deal.context';
import useStyles from './styles';

function DealPageTabsLayout() {
  const [state, dispatch] = useReducer(DealReducer, initialState);
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DealContext.Provider value={{ state, dispatch }}>
      <TabsHeader />
      <Paper className={classes.wrapper}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Suspense fallback={<LinearProgress />}>
              <Outlet />
            </Suspense>
          </Grid>
        </Grid>
      </Paper>
    </DealContext.Provider>
  );
}

export default DealPageTabsLayout;
