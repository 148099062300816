import moment from 'moment';

export const activeUntilDate = moment().add(1, 'years').format('YYYY-MM-DD');

export const getUserIgnoreApiKey = (user) => ({ ...user, apiKeyValue: null });

export const GENERATE_API_KEY_ACTION = 'GENERATE_API_KEY_ACTION';
export const TERMINATE_API_KEY_ACTION = 'TERMINATE_API_KEY_ACTION';

const GENERATE_API_KEY_TITLE = 'Generate API key';
const TERMINATE_API_KEY_TITLE = 'Terminate API key';

const GENERATE_API_KEY_TEXT = 'Are you sure, that you want to generate API key for this user?';
const TERMINATE_API_KEY_TEXT =
  'Are you sure, that you want to terminate API key for this user? It cannot be undone';

const GENERATE_API_KEY_BUTTON = 'Create key';
const TERMINATE_API_KEY_BUTTON = 'Terminate';

export const getApiKeyTitle = (action) =>
  action === GENERATE_API_KEY_ACTION ? GENERATE_API_KEY_TITLE : TERMINATE_API_KEY_TITLE;

export const getApiKeyText = (action) =>
  action === GENERATE_API_KEY_ACTION ? GENERATE_API_KEY_TEXT : TERMINATE_API_KEY_TEXT;

export const getApiKeyButton = (action) =>
  action === GENERATE_API_KEY_ACTION ? GENERATE_API_KEY_BUTTON : TERMINATE_API_KEY_BUTTON;
