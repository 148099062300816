import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

export const CustomGroupingCol = (params) => {
  const { id, rowNode, setRowExpansion } = params;
  const itemRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const item = itemRef.current;
    if (item) {
      setIsEllipsisActive(item.scrollWidth > item.clientWidth);
    }
  }, [itemRef.current?.clientWidth]);

  const handleClick = (event) => {
    setRowExpansion(id, !rowNode.childrenExpanded);
    event.stopPropagation();
  };

  return (
    <Box
      sx={{
        ml: rowNode.depth * 4,
        maxWidth: '100%'
      }}
      style={{ border: 'none' }}
    >
      {rowNode.children && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton data-testId="expandButton" onClick={handleClick} tabIndex={-1} size="small">
            {!rowNode.childrenExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <Tooltip
            role="tooltip"
            disableInteractive
            title={isEllipsisActive ? rowNode.groupingKey : ''}
          >
            <div
              ref={itemRef}
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {rowNode.groupingKey}
            </div>
          </Tooltip>
        </div>
      )}
    </Box>
  );
};
