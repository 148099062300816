import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 495,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  cardHeader: {
    padding: '24px 24px 0px 24px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '30px'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  containerWidth495: {
    width: 495
  },
  subtitle: {
    padding: '5px 24px 0px 24px'
  },
  actionsRightPosition: {
    justifyContent: 'flex-end'
  },

  headerModal: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cross: {
    cursor: 'pointer',
    '&:hover': {
      color: '#546E7A'
    }
  },
  buttonConfirmation: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21.82px'
  },
  closeButton: {
    color: '#4B5563',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21.82px',
    background: 'none',
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  },
  closeButtonConfirm: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21.82px'
  },
  cancelButton: {
    color: '#4B5563',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21.82px',
    background: 'none',
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  }
}));

export default useStyles;
