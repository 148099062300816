import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#f4f6f8',
    padding: '0px 24px 0px 24px'
  },
  home: {
    padding: '0px',
    overflowX: 'hidden'
  },
  contactUs: {
    padding: '76px 24px 0px 24px'
  },
  zeroPadding: {
    padding: '0px'
  }
}));

export default useStyles;
