import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, Tooltip } from '@mui/material';
import Score from 'src/components/Score/Score';
import useStyles from './styles';

export const DealName = ({ doc, name, numberOfDeals, lastDealElementRef, index }) => {
  const classes = useStyles();
  const docId = doc.currentDocumentId || doc.id;
  const linkStyle = doc.currentDocumentId ? classes.link : classes.minorDocLink;
  const itemRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const item = itemRef.current;
    if (item) {
      setIsEllipsisActive(item.scrollWidth > item.clientWidth);
    }
  }, [itemRef.current?.clientWidth]);

  return (
    <Grid container direction="row" className={classes.dealContainer} spacing={1}>
      <Grid item className={classes.dealNameWrap}>
        <Tooltip role="tooltip" disableInteractive title={isEllipsisActive ? name : ''}>
          <div
            ref={itemRef}
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            <Link
              key={doc.cloId}
              ref={numberOfDeals === index + 3 ? lastDealElementRef : null}
              item="true"
              className={linkStyle}
              target="_blank"
              href={`/deal/${doc.cloId}/document/${docId}`}
              underline="always"
            >
              {name}
            </Link>
          </div>
        </Tooltip>
      </Grid>

      <Grid item className={classes.dScore}>
        {doc?.dscore !== null && doc?.dscore !== undefined && (
          <Score type="dscore" mark={doc.dscore} />
        )}
      </Grid>
      <Grid item className={classes.esgScore}>
        {doc?.esgScore !== null && doc?.esgScore !== undefined && (
          <Score type="esgScore" mark={doc.esgScore} />
        )}
      </Grid>
    </Grid>
  );
};

DealName.propTypes = {
  doc: PropTypes.object,
  name: PropTypes.string,
  numberOfDeals: PropTypes.number,
  lastDealElementRef: PropTypes.func,
  index: PropTypes.number
};
