// Topics
export const LOAD_OPERATIONS_TOPICS = 'LOAD_OPERATIONS_TOPICS';
export const LOAD_OPERATIONS_TOPICS_SUCCESS = 'LOAD_OPERATIONS_TOPICS_SUCCESS';
export const SEARCH_OPERATIONS_TOPICS = 'SEARCH_OPERATIONS_TOPICS';
export const DELETE_OPERATIONS_TOPIC = 'DELETE_OPERATIONS_TOPIC';
export const DOWNLOAD_OPERATIONS_TOPICS = 'DOWNLOAD_OPERATIONS_TOPICS';
export const IMPORT_OPERATIONS_TOPICS = 'IMPORT_OPERATIONS_TOPICS';
export const COLLAPSE_CATEGORY = 'COLLAPSE_CATEGORY';

// Users
export const LOAD_OPERATIONS_USERS = 'LOAD_OPERATIONS_USERS';
export const LOAD_OPERATIONS_USERS_SUCCESS = 'LOAD_OPERATIONS_USERS_SUCCESS';
export const RESET_OPERATIONS_USERS = 'RESET_OPERATIONS_USERS';
export const OPERATIONS_EDIT_USER = 'OPERATIONS_EDIT_USER';
export const FILTER_OPERATIONS_USER_RESET = 'FILTER_OPERATIONS_USER_RESET';
export const DELETE_OPERATIONS_USER = 'DELETE_OPERATIONS_USER';
export const ADD_OPERATIONS_USER = 'ADD_OPERATIONS_USER';
export const DOWNLOAD_OPERATIONS_USERS = 'DOWNLOAD_OPERATIONS_USERS';
export const UPDATE_USERS_FILTERS = 'UPDATE_USERS_FILTERS';
export const UPDATE_USERS_AVAILABLE_FILTERS = 'UPDATE_USERS_AVAILABLE_FILTERS';
export const UPDATE_USERS_BULK_FILTERS = 'UPDATE_USERS_BULK_FILTERS';
export const SET_USERS_SORT_BY = 'SET_USERS_SORT_BY';
export const SET_USERS_SORT_DIRECTION = 'SET_USERS_SORT_DIRECTION';
export const UPDATE_USER_API_KEY = 'UPDATE_USER_API_KEY';

// Analytics
export const LOAD_OPERATIONS_ANALYTICS = 'LOAD_OPERATIONS_ANALYTICS';
export const LOAD_OPERATIONS_ANALYTICS_SUCCESS = 'LOAD_OPERATIONS_ANALYTICS_SUCCESS';
export const DOWNLOAD_OPERATIONS_ANALYTICS = 'DOWNLOAD_OPERATIONS_ANALYTICS';

// Categories
export const LOAD_OPERATIONS_CATEGORIES = 'LOAD_OPERATIONS_CATEGORIES';
export const LOAD_OPERATIONS_CATEGORIES_SUCCESS = 'LOAD_OPERATIONS_CATEGORIES_SUCCESS';

// Private Content
export const LOAD_OPERATIONS_PRIVATE_DEALS = 'LOAD_OPERATIONS_PRIVATE_DEALS';
export const LOAD_OPERATIONS_PRIVATE_DEALS_SUCCESS = 'LOAD_OPERATIONS_PRIVATE_DEALS_SUCCESS';
export const SEARCH_OPERATIONS_PRIVATE_CONTENT = 'SEARCH_OPERATIONS_PRIVATE_CONTENT';

// WORKFLOW
export const GET_WORKFLOW = 'GET_WORKFLOW';
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FILTERS_LIST = 'GET_WORKFLOW_FILTERS_LIST';
export const GET_WORKFLOW_FILTERS_LIST_SUCCESS = 'GET_WORKFLOW_FILTERS_LIST_SUCCESS';
export const UPDATE_WORKFLOW_SELECTED_FILTERS = 'UPDATE_WORKFLOW_SELECTED_FILTERS';
export const DELETE_WORKFLOW_SELECTED_FILTERS = 'DELETE_WORKFLOW_SELECTED_FILTERS';

// Deal Types
export const LOAD_OPERATIONS_DEAL_TYPES = 'LOAD_OPERATIONS_DEAL_TYPES';
export const LOAD_OPERATIONS_DEAL_TYPES_SUCCESS = 'LOAD_OPERATIONS_DEAL_TYPES_SUCCESS';
export const LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES = 'LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES';
export const LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES_SUCCESS =
  'LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES_SUCCESS';

// Products
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
