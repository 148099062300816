import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Column } from 'src/components/TableWithFilters/Column';

export const dealStatus = new Map([
  ['Active', <Column title="Active" src="/images/columnIcons/active.svg" active />],
  ['Benchmark', <Column title="Benchmark" src="/images/columnIcons/benchmark.svg" />],
  ['Draft', <Column title="Draft" src="/images/columnIcons/draft.svg" />],
  ['Expired', <Column title="Expired" src="/images/columnIcons/expired.svg" />],
  ['Inactive', <Column title="Inactive" src="/images/columnIcons/inactive.svg" />],
  ['Not present', <Column title="Not Present" src="/images/columnIcons/notPresent.svg" />],
  ['Private', <Column title="Private" src="/images/columnIcons/private.svg" />]
]);

export const ToolTipTitleForViewerUser = ({ classes }) => (
  <span className={classes?.tooltipTitle}>
    This content is for subscribers only. Please{' '}
    <Link
      target="_blank"
      style={{ color: '#A40606', textDecoration: 'underline' }}
      to="/contact-us"
    >
      contact us
    </Link>{' '}
    for details
  </span>
);

ToolTipTitleForViewerUser.propTypes = {
  classes: PropTypes.object
};
