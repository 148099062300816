import createReducer from 'src/utils/createReducer';
import * as constants from './comparison.constants';
import { getReorderedDeals, getUpdatedDealsList, getUpdatedPortfolio } from '../utils';

export const initialState = {
  expandedRows: [],
  portfolioTopics: [],
  portfolioList: {
    portfolio: [],
    loading: true
  },
  sharedPortfolioList: {
    portfolio: [],
    loading: true
  },
  portfolioDealCompareList: {
    deals: [],
    filters: {},
    loading: false
  }
};

export default createReducer(initialState, {
  [constants.GET_PORTFOLIO_LIST](state) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, loading: true }
    };
  },
  [constants.GET_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: payload, loading: false }
    };
  },
  [constants.GET_SHARED_PORTFOLIO_LIST](state) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, loading: true }
    };
  },
  [constants.GET_SHARED_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, portfolio: payload, loading: false }
    };
  },

  [constants.GET_PORTFOLIO_TOPICS_LIST](state, { payload }) {
    return {
      ...state,
      portfolioTopics: payload
    };
  },
  [constants.GET_DEAL_COMPARE_LIST](state) {
    return {
      ...state,
      portfolioDealCompareList: { ...state.portfolioDealCompareList, loading: true }
    };
  },
  [constants.GET_DEAL_COMPARE_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        deals: payload,
        loading: false
      }
    };
  },
  [constants.GET_DEAL_COMPARE_LIST_ERROR](state) {
    return {
      ...state,
      portfolioDealCompareList: { ...state.portfolioDealCompareList, loading: false }
    };
  },
  [constants.UPDATE_DEAL_COMPARE_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        deals: getUpdatedDealsList(state, payload),
        loading: false
      }
    };
  },
  [constants.UPDATE_COMPARE_FILTERS](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        filters: { ...state.portfolioDealCompareList.filters, ...payload, loading: false }
      }
    };
  },
  [constants.GET_DEAL_FOR_PORTFOLIO_LIST](state) {
    return {
      ...state,
      portfolioAvailableForPortfolio: { ...state.portfolioAvailableForPortfolio, loading: true }
    };
  },
  [constants.GET_DEAL_FOR_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioAvailableForPortfolio: {
        ...state.portfolioAvailableForPortfolio,
        deals: payload,
        loading: false
      }
    };
  },
  [constants.SET_EXPANDED_ROW](state, { payload }) {
    return {
      ...state,
      expandedRows: state.expandedRows.map((row) => (row.id === payload.id ? payload : row))
    };
  },
  [constants.SET_EXPANDED_ROWS](state, { payload }) {
    return {
      ...state,
      expandedRows: payload
    };
  },
  [constants.UPDATE_PORTFOLIO_DEALS_ORDER](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        deals: getReorderedDeals(state.portfolioDealCompareList.deals, payload)
      }
    };
  },
  [constants.UPDATE_DEALS_ORDER_IN_PORTFOLIO](state, { payload }) {
    return {
      ...state,
      portfolioList: {
        ...state.portfolioList,
        portfolio: getUpdatedPortfolio(state, payload),
        loading: false
      }
    };
  }
});
