import React from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'swiper/css/bundle';

LicenseInfo.setLicenseKey(
  '08daa33913231bc2e3c98f9ecf09c9a6Tz05NzczNCxFPTE3NTc0OTIzNzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
);

const root = createRoot(document.getElementById('root'));

AWS.config.region = process.env.REACT_APP_REGION;
axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;

root.render(<App />);

serviceWorker.unregister();
