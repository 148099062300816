import { v4 as uuid } from 'uuid';
import * as actionTypes from '../constants';

const initialState = {
  loggedIn: false,
  jwt: '',
  user: {
    first_name: '',
    last_name: '',
    email: '',
    role: ''
  },
  alerts: [],
  pdfDocumentId: null
};

// eslint-disable-next-line default-param-last
const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...initialState,
        loggedIn: true,
        jwt: action.payload.jwt,
        user: {
          ...initialState.user,
          ...action.payload
        }
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        jwt: '',
        user: {
          ...state.user,
          email: '',
          role: '',
          first_name: '',
          last_name: ''
        }
      };
    }

    case actionTypes.SESSION_USER_ATTRIBUTES: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }

    case actionTypes.SESSION_SHOW_ALERT: {
      const newAlerts = [...state.alerts, { ...action.payload, id: uuid() }];
      if (newAlerts.length > 3) {
        newAlerts.shift();
      }
      return {
        ...state,
        alerts: newAlerts
      };
    }

    case actionTypes.SESSION_HIDE_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.alertId)
      };
    }

    case actionTypes.PDF_DOCUMENT_ID: {
      return {
        ...state,
        pdfDocumentId: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
