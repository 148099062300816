import React, { useEffect, useRef, useState } from 'react';
import { Circle, DocumentScannerRounded } from '@mui/icons-material';
import { Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomTooltip from '../CustomTooltip';
import {
  circleColorMap,
  typeBackgroundColorMap
} from '../../MainLayout/HomeLayout/PostPage/postConstants';
import useStyles from './styles';
import { highlightText } from '../../utils/common';
import { reportFieldsTitleMap } from './elasticConstants';

const ReportCard = ({ data, searchValue }) => {
  const classes = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      setIsEllipsisActive(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [data.reportName]);

  return (
    <div className={classes.cardFullWidth}>
      <div className={classes.reportCardHeaderGroup}>
        <div className={classes.reportCardTitleGroup}>
          <DocumentScannerRounded />
          <CustomTooltip title={isEllipsisActive ? data.reportName : ''} placement="bottom-start">
            <Link
              className={classes.cardTitleWithWrap}
              href={`/reports/${data.reportId}`}
              underline="none"
              target="_blank"
              ref={titleRef}
            >
              {data.reportName}
            </Link>
          </CustomTooltip>
        </div>
        <Grid
          className={classes.reportType}
          style={{ backgroundColor: typeBackgroundColorMap.get(data.reportType) }}
        >
          <Circle
            className={classes.circleIcon}
            style={{ color: circleColorMap.get(data.reportType) }}
          />
          <Typography variant="body">{data.reportType}</Typography>
        </Grid>
      </div>
      <div className={classes.cardContent}>
        {data.values.map(
          (value) =>
            reportFieldsTitleMap.has(value.field) && (
              <div className={classes.cardContentRow}>
                <div className={classes.fieldName}>{reportFieldsTitleMap.get(value.field)}:</div>
                <div className={classes.fieldValue}>{highlightText(value.value, searchValue)}</div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

ReportCard.propTypes = {
  data: PropTypes.object,
  searchValue: PropTypes.string
};

export default ReportCard;
