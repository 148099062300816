export const GET_KEY_CUSTOM_FIELDS_SUCCESS = 'GET_KEY_CUSTOM_FIELDS_SUCCESS';
export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const GET_SEARCH_RESULT_SUCCESS = 'GET_SEARCH_RESULT_SUCCESS';
export const REMOVE_SEARCH_RESULT_BY_TYPE = 'REMOVE_SEARCH_RESULT_BY_TYPE';
export const SET_LOADING = 'SET_LOADING';
export const SET_SHOW_FILTER_BY_PRODUCT = 'SET_SHOW_FILTER_BY_PRODUCT';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const UPDATE_PAGINATION_PAGE = 'UPDATE_PAGINATION_PAGE';
