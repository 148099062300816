import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  openTabWrapper: {
    display: 'flex',
    gap: '8px',
    alignItems: 'flex-start',
    padding: '14px 0px 12px 18px'
  },
  tabWrapper: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    padding: '14px 0px 12px 14px',
    transition: 'gap 0.7s ease, width 0.7s ease'
  },
  tabWrapperActive: {
    color: theme.palette.primary.main,
    backgroundColor: ' #F6F8FC',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: '14px'
  },
  tabWrapperNormal: {
    color: '#7E89A18A',
    '&:hover': {
      backgroundColor: ' #F6F8FC'
    }
  },
  closedTabWrapperActive: {
    paddingLeft: '10px'
  },
  tabName: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21.82px',
    color: theme.palette.text.subtitle
  },
  tabNameActive: {
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  logo: {
    width: 187,
    height: 30
  },
  subTabsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '7.5px 0px 6.5px 47px'
  },
  arrow: {
    padding: '10px 8px'
  }
}));

export default useStyles;
