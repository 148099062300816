import React from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';

export const getTabIcon = (tabName) => {
  switch (tabName) {
    case 'Home':
      return <HomeRoundedIcon fontSize="small" />;
    case 'Deals':
      return <ListAltRoundedIcon fontSize="small" />;
    case 'Operations':
      return <HomeRepairServiceRoundedIcon fontSize="small" />;
    case 'Reports':
      return <ReceiptRoundedIcon fontSize="small" />;
    case 'About us':
      return <InfoRoundedIcon fontSize="small" />;
    case 'Contact us':
      return <ContactSupportRoundedIcon fontSize="small" />;
    default:
      return '';
  }
};

export const getPath = (location, tabLink) => location.pathname.startsWith(tabLink);

export const buildUserObject = (payload) => ({
  email: payload.email,
  role: payload['cognito:groups'][0],
  last_name: payload.family_name,
  first_name: payload.given_name
});
