import { CognitoUserPool } from 'amazon-cognito-identity-js';

export const poolData = {
  UserPoolId: process.env.REACT_APP_COG_POOL_ID,
  ClientId: process.env.REACT_APP_COG_CLIENT_ID
};

const UserPool = new CognitoUserPool(poolData);

export default UserPool;
