// PORTFOLIO
export const GET_PORTFOLIO_LIST = 'GET_PORTFOLIO_LIST';
export const GET_PORTFOLIO_LIST_SUCCESS = 'GET_PORTFOLIO_LIST_SUCCESS';
export const UPDATE_PORTFOLIO_DEALS_ORDER = 'UPDATE_PORTFOLIO_DEALS_ORDER';

export const GET_SHARED_PORTFOLIO_LIST = 'GET_SHARED_PORTFOLIO_LIST';
export const GET_SHARED_PORTFOLIO_LIST_SUCCESS = 'GET_SHARED_PORTFOLIO_LIST_SUCCESS';

// PORTFOLIO COMPARE DEAL
export const GET_DEAL_COMPARE_LIST = 'GET_DEAL_COMPARE_LIST';
export const GET_DEAL_COMPARE_LIST_SUCCESS = 'GET_DEAL_COMPARE_LIST_SUCCESS';
export const GET_DEAL_COMPARE_LIST_ERROR = 'GET_DEAL_COMPARE_LIST_ERROR';
export const UPDATE_DEAL_COMPARE_LIST_SUCCESS = 'UPDATE_DEAL_COMPARE_LIST_SUCCESS';
export const UPDATE_COMPARE_FILTERS = 'UPDATE_COMPARE_FILTERS';
export const GET_DEAL_FOR_PORTFOLIO_LIST = 'GET_DEAL_FOR_PORTFOLIO_LIST';
export const GET_DEAL_FOR_PORTFOLIO_LIST_SUCCESS = 'GET_DEAL_FOR_PORTFOLIO_LIST_SUCCESS';
export const GET_PORTFOLIO_TOPICS_LIST = 'GET_PORTFOLIO_TOPICS_LIST';
export const SET_EXPANDED_ROW = 'SET_EXPANDED_ROW';
export const SET_EXPANDED_ROWS = 'SET_EXPANDED_ROWS';
export const UPDATE_DEALS_ORDER_IN_PORTFOLIO = 'UPDATE_DEALS_ORDER_IN_PORTFOLIO';
