import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import usePrevious from 'src/utils/usePrevious';
import { onPressEnter } from 'src/utils/utils';
import useStyles from './styles';

export const TabsSearchBar = ({ placeholder, handleSubmit, style, disabled, searchValueState }) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(searchValueState);

  const prevSearchValue = usePrevious(searchValue);

  useEffect(() => {
    if (searchValue !== searchValueState) {
      setSearchValue(searchValueState);
    }
  }, [searchValueState]);

  const handleSearch = () => {
    if (prevSearchValue !== searchValue) {
      handleSubmit(searchValue);
    }
  };

  return (
    <TextField
      className={classes.wrapper}
      variant="outlined"
      type="text"
      name="searchBar"
      placeholder={placeholder}
      fullWidth
      style={style}
      disabled={disabled}
      value={searchValue}
      onChange={(event) => setSearchValue(event.target.value)}
      onBlur={handleSearch}
      onKeyDown={onPressEnter(handleSearch)}
      InputProps={{
        disableUnderline: true,
        className: classes.searchInput,
        endAdornment: <SearchIcon className={classes.searchIcon} />,
        classes: {
          input: classes.placeHolder
        }
      }}
    />
  );
};
TabsSearchBar.propTypes = {
  placeholder: PropTypes.string,
  handleSubmit: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  searchValueState: PropTypes.string
};
