import axios from 'axios';

export const getCategory = async () => {
  const { data } = await axios.get('/category');
  return data;
};

export const getCategoryOrder = async (body) => {
  await axios.put('/category/order', body);
};

export const postDealDocumentTopic = async (categoryId, documentId, params) => {
  await axios.post(`/category/${categoryId}/document/${documentId}`, params);
};

export const postCategory = async (params) => {
  const { data } = await axios.post(`/category`, params);
  return data;
};

export const putCategory = async (categoryId, params) => {
  const { data } = await axios.put(`/category/${categoryId}`, params);
  return data;
};

export const deleteCategory = async (categoryId) => {
  const { data } = await axios.delete(`/category/${categoryId}`);
  return data;
};
