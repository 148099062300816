import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '41px',
    width: '100%',
    maxWidth: '850px',
    padding: '24px 24px',
    border: '1px solid',
    borderColor: '#D8DBE0',
    backgroundColor: 'white',
    borderRadius: '16px'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    color: '#111827',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%'
  },
  fieldColumns: {
    display: 'flex',
    gap: '22px',
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  inputField: {
    width: '390px',
    height: '56px'
  },
  attachButton: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    backgroundColor: theme.palette.primary.light,
    textTransform: 'none',
    borderRadius: '4px',
    height: '48px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  attachFileIcon: {
    width: '16px',
    height: '16px',
    transform: 'rotate(45deg)'
  },
  rightActions: {
    display: 'flex',
    gap: '37px'
  },
  saveButton: {
    padding: '12px 24px',
    backgroundColor: theme.palette.primary.light,
    textTransform: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
  },
  cancelButton: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    backgroundColor: 'white',
    color: '#4B5563',
    width: '49px',
    textTransform: 'none',
    borderRadius: '4px',
    height: '48px',
    '&:hover': {
      backgroundColor: '#E5E7EB'
    }
  },
  leftActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  }
}));

export default useStyles;
