import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Typography, Grid, IconButton } from '@mui/material';
import { useStyles } from './styles';
import { ADMIN_ROLE } from '../../../../../utils/constants/stringConstants';

const EmployeeItem = ({ employee, currentUserRole, editEmployee, deleteEmployee }) => {
  const classes = useStyles();

  const fullName = useMemo(
    () => `${employee.firstName} ${employee.lastName}`,
    [employee.firstName, employee.lastName]
  );

  return (
    <Grid className={classes.wrapper} key={employee.id} item>
      <Grid className={classes.imgWrap}>
        <img className={classes.img} src={employee.imageBase64} alt={fullName} loading="lazy" />
        {currentUserRole === ADMIN_ROLE && (
          <Grid>
            <IconButton className={classes.editButton} onClick={() => editEmployee(employee)}>
              <EditOutlinedIcon className={classes.iconButton} fontSize="small" />
            </IconButton>
            <IconButton className={classes.deleteButton} onClick={deleteEmployee}>
              <DeleteOutlineOutlinedIcon className={classes.iconButton} fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Typography className={classes.fullName} align="center">
        {fullName}
      </Typography>
      <Typography className={classes.position} align="center">
        {employee.position}
      </Typography>
    </Grid>
  );
};

EmployeeItem.propTypes = {
  employee: PropTypes.object,
  currentUserRole: PropTypes.string,
  deleteEmployee: PropTypes.func,
  editEmployee: PropTypes.func
};

export default EmployeeItem;
