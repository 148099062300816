import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '0px'
  },
  buttonWrapper: {
    padding: '12px 12px 18px 12px',
    width: '100%',
    transition: 'width 0.3s ease'
  },
  closedButtonWrapper: {
    padding: '0px 0px 4px 0px',
    transition: 'padding 0.3s ease'
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '12px 2px 12px 12px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '21.82px',
    color: theme.palette.primary.light,
    textTransform: 'none',
    width: '232px',
    transition: 'width 0.3s ease, width 0.3s ease',
    '&:hover': {
      background: '#FFDCDC',
      borderColor: '#EDB1B1 !important'
    },
    '&:active': {
      background: '#FFBBBB'
    }
  },
  closedButton: {
    padding: '14px 0px 14px 14px',
    width: '47px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    transition: 'width 0.3s ease, width 0.3s ease'
  },
  activeButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.light
  },
  buttonTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'opacity 0.6s ease',
    opacity: 1
  },
  hiddenTitle: {
    opacity: 0
  }
}));

export default useStyles;
