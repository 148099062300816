/* eslint-disable no-prototype-builtins */
function createReducer(initialState, handlers) {
  // eslint-disable-next-line func-names,default-param-last
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
      // eslint-disable-next-line no-else-return
    } else {
      return state;
    }
  };
}

export default createReducer;
