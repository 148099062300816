import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';
import { colorHandler, sizeHandler, textColorHandler } from './utils';

const CustomButton = ({
  placeholder,
  variant,
  size,
  leftIcon,
  rightIcon,
  buttonColor,
  textColor,
  outlinedColor,
  onClick,
  classes,
  disabled,
  ...baseProps
}) => {
  const styles = useStyles();

  return (
    <Button
      {...baseProps}
      disabled={disabled}
      className={clsx(styles.button, classes?.customButton)}
      variant={variant}
      onClick={onClick}
      style={{
        ...sizeHandler(size),
        backgroundColor: variant !== 'text' && !disabled && colorHandler(buttonColor),
        borderColor: variant === 'outlined' && !disabled && colorHandler(outlinedColor),
        color: variant === 'outlined' && !disabled && colorHandler(outlinedColor)
      }}
    >
      <Grid
        style={{ color: !disabled && textColorHandler(textColor) }}
        className={clsx(styles.buttonContent, classes?.customButtonContent)}
      >
        {leftIcon}
        {placeholder}
        {rightIcon}
      </Grid>
    </Button>
  );
};

CustomButton.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  outlinedColor: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  baseProps: PropTypes.object
};

export default CustomButton;
