import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Button,
  Popover,
  Tooltip,
  LinearProgress
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { FixedSizeList as List } from 'react-window';
import {
  PORTFOLIO_IS_FULL,
  SELECT_PORTFOLIO,
  SHARED_PORTFOLIO
} from 'src/utils/constants/stringConstants';
import { TabsSearchBar } from 'src/components/TabsSearchBar';
import { LIMIT_OF_DEALS_IN_PORTFOLIO } from 'src/components/constants';
import useStyles from './styles';
import { getListItem } from '../../../../../utils/dropdownListUtils';
import { getTooltipTitle, isPortfolioOverlimited } from './utils';

export const AddDealToPortfolioModal = ({
  getDealForPortfolio,
  dealsList,
  portfolio,
  addDealsToPortfolios,
  amountDealsInPortfolio
}) => {
  const classes = useStyles();
  const [dealsForPortfolio, setDealsForPortfolio] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchWord, setSearchWord] = useState('');
  const [selectedDealIds, setSelectedDealIds] = useState([]);
  const [isSharedStatus, setIsSharedStatus] = useState(
    !portfolio?.id || portfolio?.category === 'shared'
  );
  const [tooltipTitle, setTooltipTitle] = useState(SELECT_PORTFOLIO);
  const [updatingPortfolio, setUpdatingPortfolio] = useState(false);

  const isPortfolioFullyFilled = useMemo(
    () => amountDealsInPortfolio >= LIMIT_OF_DEALS_IN_PORTFOLIO,
    [amountDealsInPortfolio]
  );

  useEffect(() => {
    setTooltipTitle(getTooltipTitle(portfolio, isPortfolioFullyFilled));
    setIsSharedStatus(!portfolio || portfolio.category === SHARED_PORTFOLIO);
  }, [portfolio]);

  useEffect(() => {
    setDealsForPortfolio(dealsList);
  }, [dealsList]);

  const searchDeals = () =>
    setDealsForPortfolio(
      dealsList.filter(
        (deal) => deal.dealName && deal.dealName.toLowerCase().includes(searchWord.toLowerCase())
      )
    );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    searchWord.length > 1 ? searchDeals() : setDealsForPortfolio(dealsList);
  }, [searchWord]);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedDealIds([]);
    setSearchWord('');
  };

  const headerModal = (
    <div className={classes.headerModal}>
      <span className={classes.title}>{`Add Deal to ${portfolio?.name}`}</span>
      <Close fontSize="small" className={classes.cross} onClick={handleClose} />
    </div>
  );

  const showDealListModal = (event) => {
    getDealForPortfolio();
    setAnchorEl(event.currentTarget);
    setDealsForPortfolio(dealsList);
  };

  const handleSelectOne = (event, dealId) => {
    let cachedSelectedDeals = [...selectedDealIds];

    if (event.target.checked) {
      cachedSelectedDeals.push(dealId);
      setSelectedDealIds(cachedSelectedDeals);
    } else {
      cachedSelectedDeals = cachedSelectedDeals.filter((id) => id !== dealId);
      setSelectedDealIds(cachedSelectedDeals);
    }
  };

  const saveDealToPortfolio = async () => {
    setUpdatingPortfolio(true);
    await addDealsToPortfolios(selectedDealIds);
    setUpdatingPortfolio(false);
    setAnchorEl(null);
    setSelectedDealIds([]);
    setSearchWord('');
  };

  const handleSearch = (value) => {
    setSearchWord(value);
  };

  return (
    <>
      <Tooltip disableInteractive title={tooltipTitle}>
        <IconButton
          className={classes.addButton}
          disabled={isSharedStatus || isPortfolioFullyFilled}
          onClick={showDealListModal}
          size="large"
        >
          <Add color={isSharedStatus || isPortfolioFullyFilled ? 'disabled' : 'secondary'} />
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popover}
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Card className={classes.card}>
          <CardHeader className={classes.headerWrapper} title={headerModal} />
          {updatingPortfolio && <LinearProgress />}
          <CardContent className={classes.contentBlock}>
            <div className={classes.fixedSearch}>
              <TabsSearchBar
                handleSubmit={handleSearch}
                placeholder="Search by Name"
                style={{ margin: 0, width: '100%', minWidth: '561px', padding: '0px' }}
                searchValueState={searchWord}
              />
            </div>
            <div className={classes.topDivider}>
              <Divider className={classes.divider} />
            </div>
            {dealsForPortfolio.length === 0 && (
              <p className={classes.emptyMessage}>No records to display</p>
            )}
            <div className={classes.listWrapper}>
              <List
                className={classes.listContainer}
                overscanCount={20}
                height={144}
                width={560}
                itemData={dealsForPortfolio}
                itemSize={36}
                itemCount={dealsForPortfolio.length}
              >
                {getListItem(selectedDealIds, handleSelectOne, classes, true)}
              </List>
            </div>
          </CardContent>
          <div className={classes.bottomDivider}>
            <Divider className={classes.divider} />
          </div>

          <CardActions className={classes.actionsOneBtn}>
            <Button className={classes.closeButton} variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Tooltip
              disableInteractive
              title={
                isPortfolioOverlimited(amountDealsInPortfolio, selectedDealIds, true)
                  ? PORTFOLIO_IS_FULL
                  : ''
              }
            >
              <Button
                className={classes.applyButton}
                disabled={
                  isPortfolioOverlimited(amountDealsInPortfolio, selectedDealIds, true) ||
                  !selectedDealIds.length
                }
                color="primary"
                variant="contained"
                onClick={() => saveDealToPortfolio()}
              >
                Apply
              </Button>
            </Tooltip>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

AddDealToPortfolioModal.propTypes = {
  getDealForPortfolio: PropTypes.func,
  dealsList: PropTypes.array,
  portfolio: PropTypes.object,
  addDealsToPortfolios: PropTypes.func,
  amountDealsInPortfolio: PropTypes.number
};
