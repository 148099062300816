import axios from 'axios';

export const getProduct = async () => {
  const { data } = await axios.get('/product');
  return data;
};

export const getProductAllowed = async () => {
  const { data } = await axios.get('/product/allowed');
  return data;
};

export const postProduct = async (body) => {
  const { data } = await axios.post('/product', body);
  return data;
};

export const putProduct = async (productId, body) => {
  const { data } = await axios.put(`/product/${productId}`, body);
  return data;
};

export const deleteProduct = async (productId) => {
  const { data } = await axios.delete(`/product/${productId}`);
  return data;
};

export const getProductCustomFields = async (productId) => {
  const { data } = await axios.get(`/product/${productId}/custom/field`);
  return data;
};
