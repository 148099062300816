export const headCells = [
  {
    label: 'Email',
    id: 'email'
  },
  {
    label: 'Active Until',
    id: 'activeUntil'
  },
  {
    label: 'First Name',
    id: 'firstName'
  },
  {
    label: 'Last Name',
    id: 'lastName'
  },
  {
    label: 'Client',
    id: 'company'
  },
  {
    label: 'Role',
    id: 'role'
  },
  {
    label: 'Status',
    id: 'status'
  }
];

export const columnToFilterMap = {
  email: 'emailList',
  activeUntilFrom: 'activeUntilFrom',
  activeUntilTo: 'activeUntilTo',
  firstName: 'firstNameList',
  lastName: 'lastNameList',
  company: 'companyList',
  role: 'roleList',
  status: 'statusList'
};

export const filterToColumnMap = {
  emailList: 'email',
  activeUntilFrom: 'activeUntil',
  activeUntilTo: 'activeUntil',
  firstNameList: 'firstName',
  lastNameList: 'lastName',
  companyList: 'company',
  roleList: 'role',
  statusList: 'status'
};

export const initFilters = {
  emailList: [],
  activeUntilFrom: '',
  activeUntilTo: '',
  firstNameList: [],
  lastNameList: [],
  companyList: [],
  roleList: [],
  statusList: []
};

export const tableData = (width) => [
  {
    label: 'Email',
    dataKey: 'email',
    width: width * 0.55
  },
  {
    label: 'Active Until',
    dataKey: 'activeUntil',
    width: width * 0.4
  },
  {
    label: 'First Name',
    dataKey: 'firstName',
    width: width * 0.4
  },
  {
    label: 'Last Name',
    dataKey: 'lastName',
    width: width * 0.4
  },
  {
    label: 'Client',
    dataKey: 'company',
    width: width * 0.5
  },
  {
    label: 'Role',
    dataKey: 'role',
    width: width * 0.3
  },
  {
    label: 'Status',
    dataKey: 'status',
    width: width * 0.3
  }
];
