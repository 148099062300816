import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  img: {
    width: 276,
    height: 276
  },
  editButton: {
    position: 'absolute',
    right: 49,
    top: 12,
    borderRadius: '4px',
    backgroundColor: '#F3F4F6',
    width: 32,
    height: 32,
    color: '#000000'
  },
  deleteButton: {
    position: 'absolute',
    right: 12,
    top: 12,
    borderRadius: '4px',
    backgroundColor: '#F3F4F6',
    width: 32,
    height: 32,
    color: '#000000'
  },
  imgWrap: {
    display: 'inline-block',
    position: 'relative',
    width: '276px',
    height: '276px',
    marginBottom: '12px'
  },
  fullName: {
    fontWeight: 700,
    lineHeight: '36px',
    fontSize: '24px',
    color: theme.palette.text.primary,
    marginBottom: '8px'
  },
  position: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.text.primary
  },
  wrapper: {
    width: '276px',
    height: '356px'
  },
  iconButton: {
    width: '14px',
    height: '14px'
  }
}));
