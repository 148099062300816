import axios from 'axios';

export const getEmployee = async () => {
  const { data } = await axios.get('/employee');
  return data;
};

export const postEmployee = async (body) => {
  const { data } = await axios.post('/employee', body);
  return data;
};

export const putEmployee = async (employeeId, body) => {
  const { data } = await axios.put(`/employee/${employeeId}`, body);
  return data;
};

export const deleteEmployee = async (employeeId) => {
  const { data } = await axios.delete(`/employee/${employeeId}`);
  return data;
};
