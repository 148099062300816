import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerWrapper: {
    padding: '16px 24px 0px 24px'
  },
  cross: {
    color: theme.palette.black.dark,
    cursor: 'pointer',
    '&:hover': {
      color: '#546E7A'
    }
  },
  divider: {
    color: '#E5E7EB'
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '30px',
    color: '#111827'
  },
  addButton: {
    position: 'sticky',
    zIndex: 3,
    top: 0,
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      backgroundColor: 'transparent'
    }
  },
  applyButton: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    marginLeft: '19px !important',
    padding: '8px 18px',
    textTransform: 'none',
    width: '80px',
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  },
  contentBlock: {
    height: 216,
    padding: 0,
    marginTop: '-10px'
  },
  fixedSearch: {
    width: '329px',
    padding: '16px 0px 16px 24px',
    background: 'white',
    zIndex: 1
  },
  searchInput: {
    flexGrow: 1,
    color: '#444444',
    fontSize: '12px',
    lineHeight: '14px',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 3,
    border: '1px solid #D9D9D9',
    height: 36,
    width: 300,
    padding: '0 0 0 16px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '14px'
  },

  searchIcon: {
    marginRight: 16,
    color: '#80868B'
  },
  emptyMessage: {
    width: '310px',
    textAlign: 'center',
    marginTop: '70px'
  },
  listWrapper: {
    padding: '0 0 0 24px'
  },
  listContainer: {
    overflowX: 'hidden !important',
    paddingLeft: '30px',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      height: '4px',
      borderRadius: '200px',
      backgroundColor: '#E5E7EB'
    }
  },
  labelRow: {
    display: 'flex',
    color: theme.palette.black.dark,
    paddingLeft: '8px',
    width: '580px !important',
    marginRight: '0px !important',
    '&:hover': {
      backgroundColor: '#F2F2F2'
    },
    '& .MuiCheckbox-root': {
      width: '24px',
      height: '24px',
      padding: '0 8px 0px 12px',
      color: '#00000099'
    },
    '& .Mui-checked': {
      color: theme.palette.primary.light
    }
  },
  checkboxRoot: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  actionsOneBtn: {
    justifyContent: 'flex-end',
    padding: '16px 24px 16px 0px'
  },
  iconButton: {
    height: 24
  },
  card: {
    outline: 0,
    width: '609px'
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: '16px'
    }
  },
  closeButton: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    padding: '8px 18px',
    textTransform: 'none',
    color: '#4B5563',
    '&:hover': {
      backgroundColor: theme.palette.gray.light
    }
  },
  topDivider: {
    padding: '0 24px'
  },
  bottomDivider: {
    padding: '0 24px',
    marginTop: '-3px'
  }
}));

export default useStyles;
