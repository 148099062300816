export const slotProps = {
  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
    py: '15px',
    alignItems: 'baseline',
    whiteSpace: 'normal !important'
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: 'transparent'
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'transparent'
  }
};
