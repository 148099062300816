import React, { Suspense } from 'react';
import { makeStyles } from '@mui/styles';
import { Outlet, useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import Page from 'src/components/Page';
import { getTitle } from 'src/MainLayout/pathesHandler';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    minWidth: '100vw'
  }
}));

function Auth() {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Page title={getTitle(location.pathname)}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </Page>
  );
}

export default Auth;
