export const editIconProps = {
  color: '#797979',
  width: '20px',
  height: '20px'
};

export const onlineIconProps = {
  color: '#107200',
  width: '24px',
  height: '24px'
};
