import axios from 'axios';

export const getDealType = async () => {
  const { data } = await axios.get('/deal/type');
  return data;
};

export const getGroupedDealTypes = async () => {
  const { data } = await axios.get('/deal/type/product');
  return data;
};

export const getProductDealTypes = async (productId) => {
  const { data } = await axios.get(`/deal/type/product/${productId}`);
  return data;
};

export const postDealType = async (productId, name) => {
  const { data } = await axios.post('/deal/type', { productId, name });
  return data;
};

export const putDealType = async (dealTypeId, productId, name) => {
  const { data } = await axios.put(`/deal/type/${dealTypeId}`, { productId, name });
  return data;
};

export const deleteDealType = async (dealTypeId) => {
  const { data } = await axios.delete(`/deal/type/${dealTypeId}`);
  return data;
};
