import React from 'react';
import Tabs from 'src/components/Tabs';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { getUserTabDeals } from 'src/utils/tabsUtils';
import useStyles from './styles';

function TabsHeader() {
  const classes = useStyles();
  const userRole = useSelector((state) => state.session.user.role);

  return (
    <Grid className={classes.mainWrapper}>
      <Typography className={classes.tabName}>Deals</Typography>
      <Tabs
        containerStyle={classes.container}
        wrapperTab={classes.wrapper}
        tabs={getUserTabDeals(userRole)}
        normalStyle={classes.tab}
        wrapperActiveStyle={classes.activeWrapper}
        wrapperNormalStyle={classes.normalWrapper}
        activeStyle={classes.active}
      />
    </Grid>
  );
}

export default TabsHeader;
