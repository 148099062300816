import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import { StyledGrid, StyledSubtitle, StyledTextField, StyledTitle } from './styles';

const CustomTextField = React.forwardRef(
  (
    {
      shrink,
      InputLabelProps,
      maxLength,
      inputProps,
      leftIcon,
      rightIcon,
      InputProps,
      title,
      subtitle,
      ...baseProps
    },
    ref
  ) => (
    <Grid style={{ width: '100%' }}>
      <StyledGrid>
        <StyledTitle variant="body">{title}</StyledTitle>
        <StyledSubtitle variant="body">{subtitle}</StyledSubtitle>
      </StyledGrid>
      <Grid>
        <StyledTextField
          {...baseProps}
          ref={ref}
          InputLabelProps={{ shrink, ...InputLabelProps }}
          inputProps={{
            maxLength,
            ...inputProps
          }}
          InputProps={{
            startAdornment: leftIcon ? (
              <InputAdornment position="start">{leftIcon}</InputAdornment>
            ) : null,
            endAdornment: rightIcon ? (
              <InputAdornment position="end">{rightIcon}</InputAdornment>
            ) : null,
            ...InputProps
          }}
        />
      </Grid>
    </Grid>
  )
);

CustomTextField.propTypes = {
  props: PropTypes.object,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  shrink: PropTypes.string,
  InputLabelProps: PropTypes.object,
  maxLength: PropTypes.number,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default CustomTextField;
