export default {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    button: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }
  }
};
