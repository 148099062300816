import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  addItemInput: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'space-between'
  },
  expand: {
    position: 'relative',
    maxWidth: '100px',
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  addItemIcon: {
    margin: '8px 9px 0'
  },
  deleteItemIcon: {
    margin: '8px 9px 0',
    cursor: 'pointer'
  },
  listContainer: {
    display: 'flex',
    'align-items': 'center'
  },
  list: {
    width: '100%'
  },
  rearrangeContainer: {
    display: 'flex',
    'flex-direction': 'column'
  },
  icon: {
    color: theme.palette.icon
  },
  mdCol: {
    padding: '0 16px',
    textAlign: 'center'
  },
  noBorder: {
    border: 'none'
  },
  listItem: {
    width: 620
  },
  active: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  iconHover: {
    cursor: 'pointer',
    color: theme.palette.icon
  }
}));

export default useStyles;
