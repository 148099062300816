import { LIMIT_OF_DEALS_IN_PORTFOLIO } from 'src/components/constants';
import {
  ADD_DEAL,
  DEAL_CAN_NOT_ADDED,
  PORTFOLIO_IS_FULL,
  SELECT_PORTFOLIO,
  SHARED_PORTFOLIO
} from 'src/utils/constants/stringConstants';

export const getTooltipTitle = (portfolio, isPortfolioFullyFilled) => {
  if (!!portfolio && portfolio?.category !== SHARED_PORTFOLIO) {
    return isPortfolioFullyFilled ? PORTFOLIO_IS_FULL : ADD_DEAL;
  }
  return !!portfolio ? DEAL_CAN_NOT_ADDED : SELECT_PORTFOLIO;
};

export const isPortfolioOverlimited = (amountDealsInPortfolio, selectedDealIds, isModal) =>
  isModal
    ? amountDealsInPortfolio + selectedDealIds.length > LIMIT_OF_DEALS_IN_PORTFOLIO
    : amountDealsInPortfolio + selectedDealIds.length >= LIMIT_OF_DEALS_IN_PORTFOLIO;
