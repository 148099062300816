import { ADMIN_ROLE, EDITOR_ROLE, VIEWER_USER_ROLE } from './constants/stringConstants';

export const isManagingUser = (userRole) => userRole === ADMIN_ROLE || userRole === EDITOR_ROLE;

export const isAdminUser = (userRole) => userRole === ADMIN_ROLE;

export const isViewerUser = (userRole) => userRole === VIEWER_USER_ROLE;

export const getFormattedRole = (userRole) => userRole.replace(/([a-z])([A-Z])/g, '$1 $2');

export const isRowDisabled = (row, userRole) => isViewerUser(userRole) && !row.isPublic;
