import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none'
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    textTransform: 'none'
  }
}));

export default useStyles;
