import React, { useEffect, useState } from 'react';
import { Grid, Modal, Typography, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import modalStyles from 'src/assets/scss/modalWindow';
import clsx from 'clsx';
import { useStyles } from './styles';

const BLANK_DESCRIPTION_ERROR = 'Description is required';

const EditAboutDealscribeModal = ({ open, onClose, data, submit }) => {
  const classes = useStyles();
  const modalClasses = modalStyles();
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (open) {
      setDescription(data);
    }
  }, [open, data]);

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleClose = () => {
    onClose(description !== data);
  };

  const save = () => {
    submit(description.trim());
  };

  const isDescriptionBlank = !description?.trim();

  return (
    <Modal onClose={handleClose} open={open}>
      <Grid className={clsx(modalClasses.root, classes.modalForm)}>
        <Grid className={classes.header}>
          <Typography variant="body" className={classes.headerTitle}>
            Edit Description
          </Typography>
        </Grid>
        <Grid className={classes.content}>
          <TextField
            fullWidth
            required
            multiline
            label="Description"
            margin="normal"
            name="description"
            error={isDescriptionBlank}
            helperText={isDescriptionBlank && BLANK_DESCRIPTION_ERROR}
            onChange={handleChangeDescription}
            value={description || ''}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.actions}>
          <Button onClick={handleClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            disabled={isDescriptionBlank}
            variant="contained"
            className={classes.addButton}
            onClick={save}
          >
            Save changes
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

EditAboutDealscribeModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.object,
  submit: PropTypes.func
};

EditAboutDealscribeModal.defaultProps = {
  open: false,
  data: ''
};

export default EditAboutDealscribeModal;
