export const getCategoryProducts = (stateCategories, id) =>
  stateCategories
    .filter((category) => category.id === id)
    .map((category) => category.products)
    .flat();

export const getObjectById = (dealTypesGroupedByProduct, id) =>
  dealTypesGroupedByProduct.find((item) => item.id === id);

export const getDealTypesForCategory = (dealTypesGroupedByProduct, categoryProducts) =>
  categoryProducts
    .map((item) => getObjectById(dealTypesGroupedByProduct, item.id)?.dealTypes || [])
    .flat();

export const getCategoryId = (topicsState, topic) => {
  const categoryObj = {};
  topicsState.forEach((arr) => {
    const d = arr.topics.filter((t) => t.id === topic.id);
    if (d.length > 0) {
      categoryObj.categoryId = arr.id;
      categoryObj.category = arr.category;
    }
  });

  return categoryObj;
};

export const prepareParams = (params) => ({
  ...params,
  defaultAnswers: params.defaultAnswers?.map((defaultAnswer) => ({
    ...defaultAnswer,
    defaultAnswerScores: defaultAnswer.defaultAnswerScores?.map((score) => {
      if (score.dealType?.id) {
        return {
          ...score,
          dealTypeId: score.dealType.id
        };
      }
      return score;
    })
  }))
});
