import moment from 'moment';

export const onPressEnter = (callback, disabled) => (event) => {
  if (event.key === 'Enter' && !disabled) {
    callback(event.target.value);
  }
};

export const getFormattedDate = (date) => moment(date).format().slice(0, 19);

export const handleInterruptAction = (callback, callbackProperty, disabled) => (event) => {
  if (!disabled) {
    event.preventDefault();
    event.stopPropagation();
    callback(callbackProperty);
  }
};
