import * as constants from './operations.constants';

export const loadTopics = () => ({
  type: constants.LOAD_OPERATIONS_TOPICS
});

export const loadTopicsSuccess = (topics) => ({
  type: constants.LOAD_OPERATIONS_TOPICS_SUCCESS,
  payload: topics
});

export const searchTopics = (searchWord) => ({
  type: constants.SEARCH_OPERATIONS_TOPICS,
  payload: searchWord
});

export const collapsedCategory = (category) => ({
  type: constants.COLLAPSE_CATEGORY,
  payload: category
});

export const loadUsers = () => ({
  type: constants.LOAD_OPERATIONS_USERS
});

export const loadUsersSuccess = (users) => ({
  type: constants.LOAD_OPERATIONS_USERS_SUCCESS,
  payload: users
});

export const resetUsers = () => ({
  type: constants.RESET_OPERATIONS_USERS
});

export const deleteUser = (userId) => ({
  type: constants.DELETE_OPERATIONS_USER,
  payload: userId
});

export const filterUserReset = () => ({
  type: constants.FILTER_OPERATIONS_USER_RESET
});

export const editUser = (users) => ({
  type: constants.OPERATIONS_EDIT_USER,
  payload: users
});

export const updateUsersAvailableFilters = (value) => ({
  type: constants.UPDATE_USERS_AVAILABLE_FILTERS,
  payload: value
});

export const updateUsersFilters = (value) => ({
  type: constants.UPDATE_USERS_FILTERS,
  payload: value
});

export const updateBulkUsersFilters = () => ({
  type: constants.UPDATE_USERS_BULK_FILTERS
});

export const setUsersSortBy = (value) => ({
  type: constants.SET_USERS_SORT_BY,
  payload: value
});

export const setUsersSortDirection = (value) => ({
  type: constants.SET_USERS_SORT_DIRECTION,
  payload: value
});

export const loadAnalytics = () => ({
  type: constants.LOAD_OPERATIONS_ANALYTICS
});

export const loadAnalyticsSuccess = (users) => ({
  type: constants.LOAD_OPERATIONS_ANALYTICS_SUCCESS,
  payload: users
});

export const downloadAnalytics = (status) => ({
  type: constants.DOWNLOAD_OPERATIONS_ANALYTICS,
  payload: status
});

export const loadCategories = () => ({
  type: constants.LOAD_OPERATIONS_CATEGORIES
});

export const loadCategoriesSuccess = (categories) => ({
  type: constants.LOAD_OPERATIONS_CATEGORIES_SUCCESS,
  payload: categories
});

export const downloadTopics = (status) => ({
  type: constants.DOWNLOAD_OPERATIONS_TOPICS,
  payload: status
});

export const importTopics = (status) => ({
  type: constants.IMPORT_OPERATIONS_TOPICS,
  payload: status
});

export const downloadUsers = (status) => ({
  type: constants.DOWNLOAD_OPERATIONS_USERS,
  payload: status
});

export const updateUserApiKey = (userId, newApiKeyValue) => ({
  type: constants.UPDATE_USER_API_KEY,
  payload: { userId, newApiKeyValue }
});

export const loadPrivateDeals = () => ({
  type: constants.LOAD_OPERATIONS_PRIVATE_DEALS
});

export const loadPrivateDealsSuccess = (deals) => ({
  type: constants.LOAD_OPERATIONS_PRIVATE_DEALS_SUCCESS,
  payload: deals
});

export const searchPrivateContent = (searchWord) => ({
  type: constants.SEARCH_OPERATIONS_PRIVATE_CONTENT,
  payload: searchWord
});

export const getWorkflowList = () => ({
  type: constants.GET_WORKFLOW
});

export const getWorkflowListSuccess = (items) => ({
  type: constants.GET_WORKFLOW_SUCCESS,
  payload: items
});

export const getWorkflowFiltersList = () => ({
  type: constants.GET_WORKFLOW_FILTERS_LIST
});

export const getWorkflowFiltersListSuccess = (items) => ({
  type: constants.GET_WORKFLOW_FILTERS_LIST_SUCCESS,
  payload: items
});

export const updateWorkflowSelectedFilters = (filters) => ({
  type: constants.UPDATE_WORKFLOW_SELECTED_FILTERS,
  payload: filters
});

export const deleteWorkflowSelectedFilters = () => ({
  type: constants.DELETE_WORKFLOW_SELECTED_FILTERS
});

export const loadDealTypes = () => ({
  type: constants.LOAD_OPERATIONS_DEAL_TYPES
});

export const loadDealTypesSuccess = (dealTypes) => ({
  type: constants.LOAD_OPERATIONS_DEAL_TYPES_SUCCESS,
  payload: dealTypes
});

export const loadGroupedByProductDealTypes = () => ({
  type: constants.LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES
});

export const loadGroupedByProductDealTypesSuccess = (groupedByProductDealTypes) => ({
  type: constants.LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES_SUCCESS,
  payload: groupedByProductDealTypes
});

export const loadProducts = () => ({
  type: constants.LOAD_PRODUCTS
});

export const loadProductsSuccess = (products) => ({
  type: constants.LOAD_PRODUCTS_SUCCESS,
  payload: products
});
