import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledTextField = styled(TextField)({
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2'
  },
  '& .MuiInputLabel-root': {
    color: '#AFAFAF'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#5a0002'
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: 'rgb(229, 57, 53)'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px'
  }
});

export const StyledTitle = styled(Typography)({
  fontWeight: 400,
  lineHeight: '21px',
  color: '#000000'
});

export const StyledSubtitle = styled(Typography)({
  fontWeight: 400,
  lineHeight: '24px',
  color: ' #929AB5'
});

export const StyledGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column'
});
