import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { funcValidation } from 'src/utils/validation';
import { removeExtraSpaceFromObjectValues } from 'src/utils/common';
import useStyles from '../styles';
import AddEditCustomField from './AddEditCustomField';
import { hasChanges } from '../../utils';

function AddEditProductModal({
  open,
  onClose,
  submit,
  product,
  checkUniqueProductName,
  checkUniqueCustomFieldName,
  header
}) {
  const classes = useStyles();
  const initialState = {
    values: {
      name: '',
      customFields: []
    },
    touched: {},
    errors: {},
    customFieldsErrors: false
  };
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (!!product) {
      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          name: product?.name,
          id: product?.id,
          customFields: product.customFields || []
        },
        touched: {},
        errors: {}
      }));
    } else {
      setFormState(initialState);
    }
  }, [product, open]);

  const validationFieldSettings = useCallback(
    () => ({
      name: {
        required: 'name',
        unique: ['name', (name) => checkUniqueProductName(name)]
      }
    }),
    [checkUniqueProductName]
  );

  const handleChange = ({ target: { value, name } }) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value
      },
      touched: {
        ...prevFormState.touched,
        [name]: true
      }
    }));
  };

  useEffect(() => {
    if (open) {
      const errors = {};
      const validationFields = validationFieldSettings();
      Object.keys(validationFields).forEach((fieldName) => {
        const formStateValues = formState.values[fieldName] ? `${formState.values[fieldName]}` : '';
        errors[fieldName] = funcValidation(validationFields[fieldName], formStateValues);
      });

      setFormState((prevFormState) => ({
        ...prevFormState,
        errors
      }));
    }
  }, [open, formState.values, validationFieldSettings]);

  const isSaveDisabled =
    Object.keys(formState.errors).some((key) => formState.errors[key]) ||
    !Object.values(formState.touched).some((value) => value) ||
    formState.values.customFields.some((field) => !field.type || !field.name);

  const hasError = (fieldName) => !!formState.errors[fieldName] && !!formState.touched[fieldName];

  const getErrorText = (fieldName) => (hasError(fieldName) ? formState.errors[fieldName] : '');

  const save = () => {
    const params = removeExtraSpaceFromObjectValues({ ...formState.values });
    submit(params);
    onClose(false);
  };

  const handleUnsavedClose = () => {
    const isChanged = Object.values(formState.touched).some((value) => value);
    onClose(isChanged);
  };

  const handleCustomFieldChange = (changedCustomFields, errors) => {
    const hasErrors = Object.values(errors).some((fieldError) =>
      Object.values(fieldError).some((error) => Boolean(error))
    );
    const isChanged = hasChanges(product?.customFields || [], changedCustomFields);
    setFormState((prevForm) => ({
      ...prevForm,
      values: {
        ...prevForm.values,
        customFields: changedCustomFields ? [...changedCustomFields] : []
      },
      touched: {
        ...prevForm.touched,
        customFields: isChanged
      },
      customFieldsErrors: hasErrors
    }));
  };

  const headerModal = (
    <div className={classes.headerModal}>
      <span>{header}</span>
      <CloseIcon className={classes.cross} onClick={handleUnsavedClose} />
    </div>
  );

  return (
    <Modal onClose={handleUnsavedClose} open={open}>
      <Card className={clsx(classes.root)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent>
          <Typography variant="body1" component="div">
            <TextField
              fullWidth
              label="Product name *"
              margin="normal"
              name="name"
              error={hasError('name')}
              helperText={getErrorText('name')}
              onChange={handleChange}
              value={formState.values.name || ''}
              variant="outlined"
              inputProps={{
                maxLength: 100
              }}
            />
          </Typography>
          <AddEditCustomField
            customFields={product?.customFields}
            onListChange={handleCustomFieldChange}
            checkUniqueCustomFieldName={checkUniqueCustomFieldName}
          />
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button className={classes.closeButton} onClick={handleUnsavedClose}>
            CLOSE
          </Button>
          <Button
            disabled={isSaveDisabled || formState.customFieldsErrors}
            color="primary"
            onClick={() => save()}
            variant="contained"
          >
            SAVE
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

AddEditProductModal.propTypes = {
  onClose: PropTypes.func,
  submit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  product: PropTypes.object,
  checkUniqueProductName: PropTypes.func,
  checkUniqueCustomFieldName: PropTypes.func,
  header: PropTypes.string
};

export default AddEditProductModal;
