export const dealFieldsTitleMap = new Map([
  ['cloStatus', 'Status'],
  ['currency', 'Currency'],
  ['dealName', 'Deal Name'],
  ['executionDate', 'Latest Major Doc Date'],
  ['latestIndenture', 'Latest Major Doc'],
  ['otherInfo', 'Other Information'],
  ['latestMinorDocDate', 'Latest Minor Doc Date'],
  ['latestMinorDoc', 'Latest Minor Doc'],
  ['currentDocSource', 'Current Doc Source'],
  ['productName', 'Product'],
  ['dealType', 'Deal type']
]);

export const reportFieldsTitleMap = new Map([
  ['description', 'Description'],
  ['name', 'Name'],
  ['subtitle', 'Subtitle'],
  ['title', 'Title'],
  ['type', 'Type'],
  ['tags', 'Tags'],
  ['reference', 'Reference']
]);

export const excludedFieldsMap = new Map([['ticker', 'ticker']]);
