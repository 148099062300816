import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function Tabs({
  tabs,
  normalStyle,
  activeStyle,
  containerStyle,
  wrapperTab,
  wrapperActiveStyle,
  wrapperNormalStyle,
  operationsActiveStyle,
  operationsNormalStyle
}) {
  const location = useLocation();
  return (
    <div className={containerStyle} style={{ display: 'flex' }}>
      {tabs.map((tab) => (
        <NavLink className={({ isActive }) => (isActive ? activeStyle : normalStyle)} to={tab.link}>
          <div
            className={`${wrapperTab} ${
              location.pathname.startsWith(tab.link) ? wrapperActiveStyle : wrapperNormalStyle
            }`}
          >
            <span
              key={tab.name}
              to={tab.link}
              className={
                location.pathname === tab.link ? operationsActiveStyle : operationsNormalStyle
              }
            >
              {tab.name}
            </span>
          </div>
        </NavLink>
      ))}
    </div>
  );
}

Tabs.propTypes = {
  normalStyle: PropTypes.string,
  activeStyle: PropTypes.string,
  tabs: PropTypes.array,
  containerStyle: PropTypes.string,
  wrapperTab: PropTypes.string,
  wrapperActiveStyle: PropTypes.string,
  wrapperNormalStyle: PropTypes.string,
  operationsActiveStyle: PropTypes.string,
  operationsNormalStyle: PropTypes.string
};

export default Tabs;
