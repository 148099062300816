import { createTheme } from '@mui/material';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const baseTheme = {
  palette,
  typography
};

export const theme = createTheme({ components: overrides, ...baseTheme });
