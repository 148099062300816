import * as constants from '../constants';

export const login = (payload) => (dispatch) =>
  dispatch({
    type: constants.SESSION_LOGIN,
    payload
  });

export const logout = () => (dispatch) =>
  dispatch({
    type: constants.SESSION_LOGOUT
  });

export const userAttributes = (payload) => (dispatch) =>
  dispatch({
    type: constants.SESSION_USER_ATTRIBUTES,
    payload
  });

export const showAlert = (payload) => (dispatch) =>
  dispatch({
    type: constants.SESSION_SHOW_ALERT,
    payload
  });

export const hideAlert = (alertId) => (dispatch) =>
  dispatch({
    type: constants.SESSION_HIDE_ALERT,
    alertId
  });

export const setPdfDocumentId = (payload) => (dispatch) =>
  dispatch({
    type: constants.PDF_DOCUMENT_ID,
    payload
  });
