import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1300,
    minWidth: '443px',
    maxHeight: '560px',
    borderRadius: '16px',
    border: '1px solid ',
    backgroundColor: '#FFFFFF',
    padding: '12px 24px',
    borderColor: '#E5E7EB',
    marginTop: '11px !important'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  headerGroup: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    '&:hover': {
      cursor: 'default'
    }
  },
  filterListGrid: {
    display: 'grid',
    gridTemplateRows: 'repeat(3, 1fr)',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gap: '16px',
    maxHeight: '740px',
    overflowY: 'hidden'
  },
  filterList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingRight: '5px',
    maxHeight: '400px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      height: '4px',
      borderRadius: '200px',
      backgroundColor: '#A9A9A9'
    }
  },
  filterItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxWidth: '370px'
  },
  filterItemGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    minWidth: '236px',
    maxWidth: '236px'
  },
  filterItemTitle: {
    color: '#374151',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16.41px'
  },
  datePicker: {
    width: '100%',
    userSelect: 'none'
  },
  dateInput: {
    userSelect: 'none',
    '& .MuiInput-underline:after': {
      borderBottomColor: '#B2BAC2'
    },
    '& .MuiInputLabel-root': {
      color: '#9CA3AF'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#5a0002'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px'
    }
  },
  bottomGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto'
  },
  bottomRightGroup: {
    display: 'flex',
    gap: '37px',
    justifyContent: 'flex-end'
  },
  customButtonContent: {
    fontWeight: 400,
    lineHeight: '22px',
    color: '#111827'
  },
  customButton: {
    borderRadius: '5px'
  },
  filterTitle: {
    color: '#374151',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '48px'
  }
}));

export default useStyles;
