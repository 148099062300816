import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDataGrid-root': {
      outline: 'none',
      border: '1px solid rgba(224, 224, 224)',
      color: `${theme.palette.text.primary}`,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px'
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      color: 'rgb(224, 224, 224)',
      visibility: 'visible'
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell, &.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid rgb(224, 224, 224)'
    },
    '&.MuiDataGrid-root .MuiDataGrid-pinnedColumns': {
      backgroundColor: 'white'
    }
  },
  center: {
    justifyContent: 'center'
  },
  tableContainer: {
    height: 'calc(100vh - 230px)',
    width: '100%',
    marginTop: 20
  },
  dealContainer: {
    width: 'auto',
    minWidth: 304,
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  dealNameWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    lineHeight: '18px',
    whiteSpace: 'normal',
    overflow: 'auto'
  },
  categoryCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: 'none !important'
  },
  categoryColOpen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: 'none !important',
    background: `${theme.palette.gray.superLight} !important`
  },
  bgGray: {
    background: theme.palette.gray.main
  },
  bgLightGray: {
    background: `${theme.palette.gray.secondary} !important`
  },
  bgSuperLightGray: {
    background: `${theme.palette.gray.superLight} !important`
  },
  bgWhite: { background: `#fff` },
  link: {
    color: theme.palette.link.main,
    maxWidth: 160,
    textDecorationColor: '#1E88E5'
  },
  linkBtn: {
    display: 'inline-block',
    paddingLeft: '5px',
    color: theme.palette.primary.dark
  },
  minorDocLink: {
    color: theme.palette.link.dark,
    maxWidth: 160,
    textDecorationColor: theme.palette.primary.dark
  },
  flagIcon: {
    margin: 0,
    verticalAlign: 'inherit'
  },
  noteIcon: {
    verticalAlign: 'inherit',
    marginLeft: 5
  },
  dScore: {
    fontSize: 12,
    width: 'fit-content',
    textAlign: 'center',
    lineHeight: '12px'
  },
  esgScore: {
    width: 'fit-content',
    textAlign: 'center',
    lineHeight: '12px',
    fontSize: 12
  },
  categoryScore: {
    width: 50,
    alignSelf: 'center',
    textAlign: 'center'
  },
  answerWrap: {
    width: '70%',
    overflowWrap: 'break-word'
  }
}));

export default useStyles;
