import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '0 16px 0 32px',
    height: '36px'
  },
  searchIcon: {
    marginRight: '3px',
    color: '#80868B',
    cursor: 'pointer'
  },

  searchInput: {
    flexGrow: 1,
    height: 30,
    fontSize: '12px',
    color: '#444444',
    lineHeight: '14px',
    '& input': {
      borderRadius: 0,
      padding: '18.5px 0px 18.5px 17px'
    },
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  }
}));

export default useStyles;
