import { EMAIL_IS_NOT_VALID, REQUIRED_FIELD } from 'src/utils/constants/stringConstants';

export const schema = {
  email: {
    presence: { allowEmpty: false, message: REQUIRED_FIELD },
    email: { message: EMAIL_IS_NOT_VALID }
  },
  name: {
    presence: { allowEmpty: false, message: REQUIRED_FIELD }
  },
  textMessage: {
    presence: { allowEmpty: false, message: REQUIRED_FIELD }
  }
};
