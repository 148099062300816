import * as constants from './comparison.constants';

export const getPortfolioList = () => ({
  type: constants.GET_PORTFOLIO_LIST
});

export const getPortfolioListSuccess = (items) => ({
  type: constants.GET_PORTFOLIO_LIST_SUCCESS,
  payload: items
});

export const updatePortfolioDealsOrder = (params) => ({
  type: constants.UPDATE_PORTFOLIO_DEALS_ORDER,
  payload: params
});

export const getSharedPortfolioList = () => ({
  type: constants.GET_SHARED_PORTFOLIO_LIST
});

export const getSharedPortfolioListSuccess = (items) => ({
  type: constants.GET_SHARED_PORTFOLIO_LIST_SUCCESS,
  payload: items
});

export const getDealCompareList = () => ({
  type: constants.GET_DEAL_COMPARE_LIST
});

export const getDealCompareListSuccess = (items) => ({
  type: constants.GET_DEAL_COMPARE_LIST_SUCCESS,
  payload: items
});

export const getDealCompareListError = () => ({
  type: constants.GET_DEAL_COMPARE_LIST_ERROR
});

export const updateDealCompareListSuccess = (items) => ({
  type: constants.UPDATE_DEAL_COMPARE_LIST_SUCCESS,
  payload: items
});

export const getPortfolioTopics = (topics) => ({
  type: constants.GET_PORTFOLIO_TOPICS_LIST,
  payload: topics
});

export const getDealForPortfolioList = () => ({
  type: constants.GET_DEAL_FOR_PORTFOLIO_LIST
});

export const getDealForPortfolioListSuccess = (items) => ({
  type: constants.GET_DEAL_FOR_PORTFOLIO_LIST_SUCCESS,
  payload: items
});

export const setExpandedRow = (row) => ({
  type: constants.SET_EXPANDED_ROW,
  payload: row
});

export const setExpandedRows = (rows) => ({
  type: constants.SET_EXPANDED_ROWS,
  payload: rows
});

export const updateDealsOrderInPortfolio = (id, params) => ({
  type: constants.UPDATE_DEALS_ORDER_IN_PORTFOLIO,
  payload: { id, params }
});
