import React from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Toolbar, Link, Button, Typography, Grid } from '@mui/material';
import useStyles from './styles';

export const Header = ({ className, hideMenu, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/auth/login');
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolBar}>
        <Grid className={classes.headerContainer}>
          <Link className={classes.companyName} to="/home" component={NavLink}>
            <img src="/images/logos/dealscribe_logo.png" alt="Home page" className={classes.logo} />
          </Link>
          <Grid className={classes.buttonGroup}>
            <Typography
              id="signIn-link"
              className={classes.logoutButton}
              color="inherit"
              onClick={handleLogin}
            >
              Sign in
            </Typography>
            <Button href="/auth/sign-up" variant="contained" className={classes.freeTrial}>
              Free trial
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  hideMenu: PropTypes.bool
};
