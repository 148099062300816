import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { funcValidation } from 'src/utils/validation';
import { removeExtraSpaceFromObjectValues } from 'src/utils/common';
import useStyles from '../styles';

function AddEditDealTypeModal({
  open,
  onClose,
  submit,
  dealType,
  checkUniqueDealTypeName,
  header,
  products
}) {
  const classes = useStyles();
  const initialState = {
    values: {
      name: '',
      product: null
    },
    touched: {},
    errors: {}
  };

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (!!dealType && open) {
      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          name: dealType?.name,
          id: dealType?.id,
          product: dealType?.product && products.find((product) => product.id === dealType.product)
        },
        touched: {},
        errors: {}
      }));
    } else {
      setFormState(initialState);
    }
  }, [dealType, open]);

  const validationFieldSettings = useCallback(
    () => ({
      name: {
        required: 'name',
        unique: ['name', (name) => checkUniqueDealTypeName(name)]
      }
    }),
    [checkUniqueDealTypeName]
  );

  const changeProduct = (productId) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        product: products.find((product) => product.id === productId)
      },
      touched: {
        ...prevFormState.touched,
        product: true
      }
    }));
  };

  const handleChange = ({ target: { value, name } }) => {
    const errors = {};
    const validationFields = validationFieldSettings();
    Object.keys(validationFields).forEach((fieldName) => {
      errors[fieldName] = funcValidation(validationFields[fieldName], value);
    });

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value
      },
      touched: {
        ...prevFormState.touched,
        [name]: true
      },
      errors: {
        ...prevFormState.errors,
        ...errors
      }
    }));
  };

  const hasValueChanged = (oldValue, newValue) =>
    typeof newValue === 'object' ? newValue.id !== oldValue : newValue !== oldValue;

  const haveValuesChanged = () =>
    !!dealType
      ? Object.keys(formState.touched).some((key) =>
          hasValueChanged(dealType[key], formState.values[key])
        )
      : Object.keys(formState.touched).some((key) =>
          hasValueChanged(initialState.values[key], formState.values[key])
        );

  const hasSomeError = Object.keys(formState.errors).some((key) => formState.errors[key]);

  const save = () => {
    const params = removeExtraSpaceFromObjectValues({ ...formState.values });
    submit(params);
    onClose(false);
  };

  const handleUnsavedClose = () => {
    onClose(Object.keys(formState.touched).length > 0 ? haveValuesChanged() : false);
  };

  const headerModal = (
    <div className={classes.headerModal}>
      <span>{header}</span>
      <CloseIcon className={classes.cross} onClick={handleUnsavedClose} />
    </div>
  );

  return (
    <Modal onClose={handleUnsavedClose} open={open}>
      <Card className={clsx(classes.root)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent>
          <Typography variant="body1" component="div">
            <TextField
              fullWidth
              label="Deal Type name *"
              margin="normal"
              name="name"
              error={hasSomeError}
              helperText={formState.errors.name}
              onChange={handleChange}
              value={formState.values.name || ''}
              variant="outlined"
              inputProps={{
                maxLength: 100
              }}
            />
            <Autocomplete
              autoHighlight
              size="medium"
              fullWidth
              options={products}
              getOptionLabel={(option) => option.name}
              onChange={(event, option) => changeProduct(option?.id)}
              value={formState.values.product}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  label="Deal Type Product *"
                  variant="outlined"
                />
              )}
            />
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button className={classes.closeButton} onClick={handleUnsavedClose}>
            CLOSE
          </Button>
          <Button
            disabled={
              hasSomeError ||
              !formState.values.product ||
              !haveValuesChanged() ||
              !formState.values.name
            }
            color="primary"
            onClick={() => save()}
            variant="contained"
          >
            SAVE
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

AddEditDealTypeModal.propTypes = {
  onClose: PropTypes.func,
  submit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  dealType: PropTypes.object,
  checkUniqueDealTypeName: PropTypes.func,
  header: PropTypes.string,
  products: PropTypes.array
};

export default AddEditDealTypeModal;
