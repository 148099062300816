import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from 'src/assets/scss/modalConfirmation';

function UnsavedMessageModal({ open, onClose, onCancel }) {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  const headerModal = (
    <div className={classes.headerModal}>
      <span className={classes.title}>Unsaved changes</span>
      <CloseIcon fontSize="small" className={classes.cross} onClick={onCancel} />
    </div>
  );

  return (
    <Modal open={open} onClose={onCancel}>
      <Card className={clsx(classes.root, classes.containerWidth495)}>
        <CardHeader className={classes.cardHeader} title={headerModal} />
        <CardContent className={classes.subtitle}>
          <Typography variant="body1" component="div">
            Your changes are not saved. Are you sure you would like to close the window?
          </Typography>
        </CardContent>
        <CardActions className={classes.actionsRightPosition}>
          <Button className={classes.cancelButton} variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button className={classes.closeButtonConfirm} variant="contained" onClick={onClose}>
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

UnsavedMessageModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onCancel: PropTypes.func
};

UnsavedMessageModal.defaultProps = {
  open: false
};

export default UnsavedMessageModal;
