import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  fileNameGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fileName: {
    maxWidth: '216px',
    color: '#A40606',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  deleteFileIcon: {
    width: '16px',
    height: '16px',
    color: '#A40606'
  }
}));

export default useStyles;
