import { makeStyles } from '@mui/styles';
import palette from 'src/theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'space-between'
  },
  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cross: {
    cursor: 'pointer',
    '&:hover': {
      color: '#546E7A'
    }
  },
  icon: {
    color: palette.icon,
    cursor: 'pointer'
  },
  deleteItemIcon: {
    marginLeft: '8px',
    fontSize: '20px'
  },
  customFieldHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 18
  },
  cardContent: {
    maxHeight: '300px',
    overflowY: 'auto'
  },
  customFieldsWrapper: {
    display: 'flex',
    padding: 10,
    justifyContent: 'space-between'
  },
  customField: {
    width: 280
  },
  buttonContainer: {
    marginLeft: 'calc(50% + 150px)'
  },
  '@media (max-width: 600px)': {
    buttonContainer: {
      marginLeft: 'calc(50% + 50px)'
    }
  },
  closeButton: {
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  }
}));

export default useStyles;
