import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { funcValidation } from 'src/utils/validation';
import { removeExtraSpaceFromObjectValues } from 'src/utils/common';
import OptionDropdown from 'src/components/OptionDropdown';
import useStyles from '../styles';

function AddEditCategoryModal({
  open,
  onClose,
  submit,
  category,
  checkUniqueCategoryName,
  products,
  header
}) {
  const classes = useStyles();
  const initialState = {
    values: {
      name: '',
      id: null,
      exportable: false,
      products: [],
      productsName: []
    },
    touched: {},
    errors: {}
  };
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (!!category && open) {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          name: category?.name,
          id: category?.id,
          exportable: category?.isExportable,
          products: category?.products ? category?.products.map((product) => product.id) : [],
          productsName: category?.products || []
        },
        touched: {},
        errors: {}
      });
    } else {
      setFormState(initialState);
    }
  }, [category, open]);

  const validationFieldSettings = useCallback(
    () => ({
      name: {
        required: 'name',
        unique: ['name', (name) => checkUniqueCategoryName(name)]
      }
    }),
    [checkUniqueCategoryName]
  );

  const handleChange = ({ target }) => {
    const errors = {};
    const validationFields = validationFieldSettings();
    Object.keys(validationFields).forEach((fieldName) => {
      errors[fieldName] = funcValidation(validationFields[fieldName], target.value);
    });
    let { value } = target;
    if (target.value === 'true') value = true;
    if (target.value === 'false') value = false;
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [target.name]: value
      },
      touched: {
        ...prevFormState.touched,
        [target.name]: true
      },
      errors: {
        ...prevFormState.errors,
        ...errors
      }
    }));
  };

  const updateProducts = (updatedProducts) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        productsName: updatedProducts,
        products: updatedProducts.map((product) => product.id)
      },
      touched: {
        ...prevFormState.touched,
        products: !!updatedProducts.length
      }
    }));
  };

  const hasSomeError = Object.keys(formState.errors).some((key) => formState.errors[key]);

  const save = () => {
    const params = removeExtraSpaceFromObjectValues({ ...formState.values });
    submit(params);
    onClose(false);
  };

  const handleUnsavedClose = () => {
    const currentValues = Object.values(formState.values);
    const copyData = { ...category };
    const prevValues = Object.values(copyData);
    let changed;

    if (Object.keys(formState.touched).length > 0) {
      changed = currentValues.map((value, index) => value === prevValues[index]);
    }

    const isChanged =
      Object.keys(formState.touched).length > 0 ? changed.some((item) => item === false) : false;

    onClose(isChanged);
  };

  const areProductsEqual = (arr1, arr2) => {
    const productsId = arr1.map((product) => product.id);
    return arr1.length === arr2.length && productsId.every((item, index) => item === arr2[index]);
  };

  const hasValueChanged = (oldValue, newValue) =>
    typeof newValue !== 'object' ? newValue !== oldValue : !areProductsEqual(oldValue, newValue);

  const haveValuesChanged = () =>
    !!category
      ? Object.keys(formState.touched).some((key) =>
          hasValueChanged(category[key], formState.values[key])
        )
      : Object.keys(formState.touched).some((key) =>
          hasValueChanged(initialState.values[key], formState.values[key])
        );

  const headerModal = (
    <div className={classes.headerModal}>
      <span>{header}</span>
      <CloseIcon className={classes.cross} onClick={handleUnsavedClose} />
    </div>
  );

  return (
    <Modal onClose={handleUnsavedClose} open={open}>
      <Card className={clsx(classes.root)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent>
          <Typography variant="body1" component="div">
            <TextField
              fullWidth
              label="Category name *"
              margin="normal"
              name="name"
              error={hasSomeError}
              helperText={formState.errors.name}
              onChange={handleChange}
              value={formState.values.name || ''}
              variant="outlined"
              inputProps={{
                maxLength: 100
              }}
            />
            <OptionDropdown
              size="medium"
              options={products}
              selectedOptions={formState.values.productsName}
              updateOptions={updateProducts}
              styles={{ margin: '18px 0' }}
              label="Products"
            />
            <FormControl className={classes.radioGroup}>
              <Typography>Exportable score</Typography>
              <RadioGroup
                row
                value={formState.values.exportable}
                onChange={handleChange}
                name="exportable"
              >
                <FormControlLabel value control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <div>
            <Button className={classes.closeButton} onClick={handleUnsavedClose}>
              CLOSE
            </Button>
            <Button
              disabled={hasSomeError || !formState.values.name || !haveValuesChanged()}
              color="primary"
              onClick={() => save()}
              variant="contained"
            >
              SAVE
            </Button>
          </div>
        </CardActions>
      </Card>
    </Modal>
  );
}

AddEditCategoryModal.propTypes = {
  onClose: PropTypes.func,
  submit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  category: PropTypes.object,
  checkUniqueCategoryName: PropTypes.func,
  products: PropTypes.array,
  header: PropTypes.string
};

export default AddEditCategoryModal;
