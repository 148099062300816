import _ from 'lodash';
import { isArrayNotEqual } from 'src/utils/common';

export const CUSTOM_FIELD_NAME_ERROR = 'Custom field name is required and should be unique!';

export const hasChanges = (oldCustomFields, changedCustomFields) => {
  if (!oldCustomFields?.length && !changedCustomFields?.length) {
    return false;
  }
  if (oldCustomFields?.length !== changedCustomFields?.length) {
    return true;
  }
  return oldCustomFields.some((oldField, index) => {
    const changedCustomField = changedCustomFields[index];
    const oldCustomFieldWithoutKey = { ...oldField };
    const changedCustomFieldWithoutKey = { ...changedCustomField };
    delete oldCustomFieldWithoutKey.key;
    delete changedCustomFieldWithoutKey.key;

    const result =
      !_(oldCustomFieldWithoutKey).isEqual(changedCustomFieldWithoutKey) ||
      isArrayNotEqual(oldField, changedCustomField);
    return result;
  });
};

export const availableTypes = [{ type: 'String' }, { type: 'Number' }, { type: 'Date' }];
