import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import AuthGuard from 'src/components/AuthGuard';
import { hideAlert } from 'src/redux/actions';
import { Header } from 'src/components/Header/Header';
import { defaultLocalStorageUserSettings } from 'src/utils/userLocalStorageSettings';
import Page from 'src/components/Page';
import { slotProps } from 'src/components/Login/slotProps';
import { createBrowserHistory } from 'history';
import { SideBar } from 'src/components/SideBar/SideBar';
import useStyles from './styles';
import { getSourcePath, getTitle } from './pathesHandler';
import { initDefaultAxiosConfig } from '../utils/axios';

function MainLayout() {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.session.alerts);
  const userRole = useSelector((state) => state.session.user.role);
  const history = createBrowserHistory();
  const classes = useStyles();
  const location = useLocation();
  const isNotPdf = () => location?.pathname?.indexOf('document-pdf') === -1;

  initDefaultAxiosConfig(history, dispatch);

  const closeAlertMessage = (alertId) => {
    dispatch(hideAlert(alertId));
  };

  useEffect(() => {
    const localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    if (!localStorageUserSettings) {
      localStorage.setItem('userSettings', JSON.stringify(defaultLocalStorageUserSettings));
    }
  });

  const dynamicClassName = () => {
    if (!isNotPdf()) return '';
    const pathClasses = {
      home: classes.home,
      errors: classes.zeroPadding,
      deal: classes.zeroPadding,
      'about-us': classes.zeroPadding,
      'contact-us': classes.contactUs
    };

    return pathClasses[getSourcePath(location.pathname)] || classes.content;
  };
  const dynamicContent = () => {
    if (isNotPdf() && userRole) {
      return (
        <SideBar
          mainContent={
            <Suspense fallback={<LinearProgress />}>
              <Outlet />
            </Suspense>
          }
        />
      );
    }
    if (location.pathname === '/home' && !userRole) {
      return (
        <>
          <Header />
          <Suspense fallback={<LinearProgress />}>
            <Outlet />
          </Suspense>
        </>
      );
    }

    return (
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    );
  };

  return (
    <Page title={getTitle(location.pathname)}>
      <AuthGuard>
        {alerts.map(
          (alert, index) =>
            alert.isShown && (
              <Snackbar
                key={alert.id}
                ClickAwayListenerProps={{ onClickAway: () => null }}
                style={{ top: `${100 + index * 60}px` }}
                open={alert.isShown}
                autoHideDuration={6000}
                onClose={() => closeAlertMessage(alert.id)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert
                  onClose={() => closeAlertMessage(alert.id)}
                  severity={alert.type}
                  variant="filled"
                  sx={slotProps}
                >
                  {alert.message}
                </Alert>
              </Snackbar>
            )
        )}
        <div className={dynamicClassName()}>{dynamicContent()}</div>
      </AuthGuard>
    </Page>
  );
}

export default MainLayout;
