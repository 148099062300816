import React, { useReducer } from 'react';
import ComparisonReducer, { initialState } from './context/comparison.reducer';
import ComparisonContext from './context/comparison.context';
import ComparisonPage from './ComparisonPage';

function ComparisonLayout() {
  const [state, dispatch] = useReducer(ComparisonReducer, initialState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ComparisonContext.Provider value={{ state, dispatch }}>
      <ComparisonPage />
    </ComparisonContext.Provider>
  );
}

export default ComparisonLayout;
