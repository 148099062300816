import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '24px 24px 14px 24px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 343
  },
  select: {
    height: 37,
    margin: '8px 0 24px -6px'
  },
  marginTop: {
    marginTop: 10
  },
  focus: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  actionsContainer: {
    justifyContent: 'flex-end'
  },
  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cross: {
    cursor: 'pointer',
    '&:hover': {
      color: '#546E7A'
    }
  },
  redButton: {
    color: `${theme.palette.primary.main}`,
    fontSize: '0.8125rem',
    height: 34,
    '&:hover': {
      backgroundColor: 'rgba(38, 50, 56, 0.04) !important'
    },
    '& .MuiBadge-badge': {
      color: 'white',
      backgroundColor: theme.palette.primary.main
    }
  },
  hidden: {
    display: 'none'
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: '13px'
    }
  }
}));

export default useStyles;
