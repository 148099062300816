import axios from 'axios';

export const getPortfolio = async () => {
  const { data } = await axios.get('/portfolio');
  return data;
};

export const getPortfolioDeal = async (portfolioId, params) => {
  const { data } = await axios.get(`/portfolio/${portfolioId}/clo`, params);
  return data;
};

export const getAvailableDealForPortfolio = async (portfolioId) => {
  const { data } = await axios.get(`/portfolio/${portfolioId}/clo/available`);
  return data;
};

export const getSharedPortfolioDeal = async (portfolioId, params) => {
  const { data } = await axios.get(`/portfolio/${portfolioId}/share/clo`, params);
  return data;
};

export const postPortfolioName = async (portfolioName) => {
  const { data } = await axios.post('/portfolio', { name: portfolioName });
  return data;
};

export const putPortfolio = async (params) => {
  await axios.put(`/portfolio`, params);
};

export const putPortfolioToPortfolio = async (portfolioId, dealIds) => {
  await axios.put(`/portfolio/${portfolioId}/clo`, { cloIds: dealIds });
};

export const putPortfolioOrder = async (params) => {
  const { data } = await axios.put('/portfolio/order', params);
  return data;
};

export const deletePortfolio = async (portfolioId) => {
  await axios.delete(`/portfolio/${portfolioId}`);
};

export const getDealPortfolio = async (dealId) => {
  const { data } = await axios.get(`/portfolio/clo/${dealId}`);
  return data;
};

export const getDealsPortfolio = async (dealIds) => {
  const { data } = await axios.post(`/portfolio/clo/filter`, { cloIds: dealIds });
  return data;
};

export const putPortfolioToDeal = async (params) => {
  const { data } = await axios.put('/portfolio/clo', params);
  return data;
};

export const putPortfoliosToDeals = async (params) => {
  const { data } = await axios.put('/portfolio/clos', params);
  return data;
};

export const deleteDealFromPortfolio = async (portfolioId, dealId) => {
  await axios.delete(`/portfolio/${portfolioId}/clo/${dealId}`);
};

export const putSharedPortfolio = async (portfolioId, params) => {
  const { data } = await axios.put(`/portfolio/${portfolioId}/share`, params);
  return data;
};

export const getSharedPortfolio = async () => {
  const { data } = await axios.get(`/portfolio/share`);
  return data;
};

export const getSharedUserPortfolio = async (portfolioId) => {
  const { data } = await axios.get(`/portfolio/${portfolioId}/share/user`);
  return data;
};

export const deleteSharedPortfolio = async (portfolioId) => {
  const { data } = await axios.put(`/portfolio/${portfolioId}/share/remove`);
  return data;
};
