import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

export const BadgeForProduct = ({ option, width, padding, fontSize, showBadge }) => {
  const itemRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const item = itemRef.current;
    if (item) {
      setIsEllipsisActive(item.scrollWidth > item.clientWidth);
    }
  }, []);

  const overflowStyle = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    backgroundColor: '#A40606',
    color: 'white',
    padding: `${padding}`,
    borderRadius: '6px',
    fontSize: `${fontSize}px`,
    width: `${width}px`,
    textAlign: 'center'
  };

  return (
    showBadge && (
      <Tooltip disableInteractive title={(isEllipsisActive && option.product) || ''}>
        <div ref={itemRef} style={overflowStyle}>
          {option.product}
        </div>
      </Tooltip>
    )
  );
};

BadgeForProduct.propTypes = {
  option: PropTypes.object,
  width: PropTypes.number,
  fontSize: PropTypes.number,
  padding: PropTypes.string,
  showBadge: PropTypes.bool
};

BadgeForProduct.defaultProps = {
  showBadge: true
};
