import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material';
import { Checkbox, Tooltip } from '@mui/material';

const Option = ({ option, selected, loading, props }) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [option.name]);

  return (
    <li {...props}>
      <Checkbox
        key={option.id}
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={selected}
        disabled={loading}
      />
      <Tooltip disableInteractive title={isOverflow ? option.name : ''}>
        <div
          ref={textRef}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {option.name}
        </div>
      </Tooltip>
    </li>
  );
};

Option.propTypes = {
  option: PropTypes.object,
  loading: PropTypes.bool,
  selected: PropTypes.bool,
  props: PropTypes.object
};

export default Option;
