import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    height: '32px',
    gap: '4px',
    padding: '5px 7px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19.1px',
    color: theme.palette.text.subtitle,
    backgroundColor: '#B0B7CB0D'
  },
  blue: {
    backgroundColor: '#8BA5E40D',
    color: '#6487DB'
  },
  icon: {
    width: '18px',
    height: '18px'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;
