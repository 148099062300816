import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paper, Grid } from '@mui/material';
import { postAnalyticsEvent } from 'src/services/api/analytics';
import { AboutDealscribe } from './components/AboutDealscribe';
import { KeyIndividuals } from './components/KeyIndividuals';
import { useStyles } from './styles';
import Footer from '../HomeLayout/HomePage/Footer/Footer';
import { PAGE_ACCESS_EVENT } from '../../utils/constants/stringConstants';

export const AboutUsPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const contactUsRef = useRef(null);
  const session = useSelector((state) => state.session);

  const scrollToBottom = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const postAnalyticsEventHandler = async () => {
    const data = {
      event: PAGE_ACCESS_EVENT,
      details: 'About us'
    };
    await postAnalyticsEvent(data);
  };

  useEffect(() => {
    if (location.search === '?contact-us') scrollToBottom();
    if (session.user.email) postAnalyticsEventHandler();
  }, []);

  return (
    <Paper className={classes.container}>
      <Grid className={classes.aboutUsPage}>
        <AboutDealscribe commonClasses={classes} />
        <KeyIndividuals commonClasses={classes} />
      </Grid>
      <Footer />
    </Paper>
  );
};
