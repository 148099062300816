export const getReorderedDeals = (deals, params) =>
  deals.map((deal) => {
    const parameter = params.cloOrders.find((param) => param.entityId === deal.id);
    if (parameter) {
      return { ...deal, position: parameter.position };
    }
    return deal;
  });

export const getUpdatedDealsList = (state, payload) => {
  const existingDealsSet = new Set(state.portfolioDealCompareList.deals.map((deal) => deal.id));
  const uniquePayload = payload.filter((payloadDeal) => !existingDealsSet.has(payloadDeal.id));
  return state.portfolioDealCompareList.deals.concat(uniquePayload);
};

export const getUpdatedPortfolio = (state, payload) =>
  state.portfolioList.portfolio.map((portfolio) => {
    if (portfolio.portfolioDto.id === payload.id) {
      return { ...portfolio, cloDtoList: getReorderedDeals(portfolio.cloDtoList, payload.params) };
    }
    return portfolio;
  });
