import axios from 'axios';

export const postUser = async (params) => {
  const { data } = await axios.post('/user/filter', params);
  return data;
};

export const getInnerUser = async () => {
  const { data } = await axios.get('/user/inner');
  return data;
};

export const patchUser = async (params) => {
  const { data } = await axios.patch('/user/', params);
  return data;
};

export const postUserCreateNew = async (params) => {
  await axios.post('/user', params);
};

export const getUserExportWithFilters = async (body, requestId) => {
  const { data } = await axios.post(`/user/filter/export?requestId=${requestId}`, body);
  return data;
};

export const putUser = async (params) => {
  const { data } = await axios.put('/user/', params);
  return data;
};

export const deleteUser = async (userId) => {
  await axios.delete(`/user/${userId}`);
};

export const deleteUserApiKey = async (userId) => {
  await axios.delete(`/user/${userId}/api/key`);
};

export const createUserApiKey = async (userId) => {
  const { data } = await axios.post(`/user/${userId}/api/key`);
  return data;
};

export const getUserLimits = async () => {
  const { data } = await axios.get('/user/current/limits');
  return data;
};
