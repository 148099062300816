import * as actionsCreator from './context/operations.actions';
import { getProduct } from '../../services/api/product';
import { showAlert } from '../../redux/actions';
import * as constants from '../../utils/constants/stringConstants';

export const getProducts = async (dispatch, dispatchRedux) => {
  dispatch(actionsCreator.loadProducts());
  try {
    const data = await getProduct();
    dispatch(actionsCreator.loadProductsSuccess(data));
  } catch (error) {
    dispatchRedux(
      showAlert({ isShown: true, type: 'error', message: error.response.data.message })
    );
  }
};

export const processRequestState = (dispatchRedux, requestState) => {
  if (requestState.url) {
    const link = document.createElement('a');
    link.href = requestState.url;
    document.body.appendChild(link);
    link.click();
  } else {
    dispatchRedux(
      showAlert({
        isShown: true,
        type: 'error',
        message: constants.DOWNLOAD_FILE_ERROR_MESSAGE
      })
    );
  }
};
