import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.white,
    padding: '0px 80px',
    boxShadow: '0px 4px 16px 0px #00000012',
    width: '100vw',
    right: 'auto',
    left: 0
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  logo: {
    width: 187,
    height: 30
  },
  freeTrial: {
    height: '48px',
    padding: '8px 32px',
    gap: '8px',
    borderRadius: '40px',
    backgroundColor: theme.palette.primary.light,
    opacity: '0px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  companyName: {
    width: '100%',
    display: 'flex',
    padding: '16px 16px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logoutButton: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    cursor: 'pointer',
    color: theme.palette.primary.light,
    marginRight: '40px'
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  }
}));

export default useStyles;
