import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import ContactEmailItem from '../HomeLayout/HomePage/Footer/ContactEmailItem/ContactEmailItem';

const AdressBlock = ({ city, country, adress, number }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.contactWrapper}>
      <Typography className={classes.city}>{city}</Typography>
      <Typography className={classes.country}>{country}</Typography>
      <Typography className={classes.adress}>
        {adress}
        <br /> {number}
      </Typography>
      <ContactEmailItem classes={classes} />
    </Grid>
  );
};

AdressBlock.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  adress: PropTypes.string,
  number: PropTypes.string
};

export default AdressBlock;
