import axios from 'axios';

export const postAnalytics = async (data) => {
  await axios.post('/analytics', data);
};

export const postAnalyticsEvent = async (data) => {
  await axios.post('/analytics/event', data);
};

export const postAnalyticsFilter = async (body, params) => {
  const { data } = await axios.post('/analytics/filter', { ...body }, { params });
  return data;
};

export const postAnalyticsExport = async (body, params) => {
  const { data } = await axios.post('/analytics/filter/export', { ...body }, { params });
  return data;
};
