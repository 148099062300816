export const sizeHandler = (size) => {
  switch (size) {
    case 'small':
      return {
        fontWeight: 600,
        lineHeight: '16.8px',
        fontSize: '12px'
      };
    case 'medium':
      return {
        fontWeight: 600,
        lineHeight: '21px',
        fontSize: '14px'
      };
    case 'large':
      return {
        fontWeight: 600,
        lineHeight: '27px',
        fontSize: '18px'
      };
    default:
      return {
        fontWeight: 600,
        lineHeight: '16.8px',
        fontSize: '12px'
      };
  }
};

export const colorHandler = (color) => {
  switch (color) {
    case 'primary':
      return '#A40606';
    case 'secondary':
      return '#727272';

    case 'red':
      return '#7F1D1D';

    case 'black':
      return '#111827';

    case 'white':
      return '#FFFFFF';

    case 'gray':
      return '#E8E8E8';

    case 'light-gray':
      return '#F2F2F2';

    case 'dark-gray':
      return '#D1D5DB';

    default:
      return '#A40606';
  }
};

export const textColorHandler = (color) => {
  switch (color) {
    case 'primary':
      return '#FFFFFF';

    case 'secondary':
      return '#000000';

    case 'gray':
      return '#4B5563';

    default:
      return '#FFFFFF';
  }
};
