import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 60,
    height: 30,
    maxHeight: 30,
    borderRadius: 4
  },
  d: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.white,
    border: '1px solid #A91414'
  },
  esg: {
    border: `1px solid ${theme.palette.black.main}`
  },
  category: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.gray.main,
    border: '1px solid rgba(0, 0, 0, 0.23)'
  }
}));

export default useStyles;
