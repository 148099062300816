import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  row: {
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'row'
  },
  wrapper: {
    marginBottom: '104px'
  }
}));
