import React from 'react';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { cutStr } from 'src/utils/common';
import { LIMIT_TAGS_WIDTH_BLOCK } from 'src/utils/constants/numericConstants';
import { IconButton } from '@mui/material';
import useStyles from './styles';

const Tag = ({
  value,
  index,
  getTagProps,
  removeOption,
  setTagRef,
  tagRefs,
  inputWidth,
  limitTags,
  disabled,
  classes
}) => {
  const defaultClasses = useStyles();
  const tagWidth = tagRefs.current[index]?.offsetWidth || 0;
  const allowedWidth = inputWidth - LIMIT_TAGS_WIDTH_BLOCK;
  let label = value.name;
  label = cutStr(
    value.name,
    Math.floor((allowedWidth / tagWidth) * value.name.length) - (limitTags ? 4 : 0)
  );
  return (
    <Chip
      className={classes?.chip}
      disabled={disabled}
      key={value.id}
      label={limitTags ? label : cutStr(value.name, 35)}
      deleteIcon={
        <IconButton className={defaultClasses.iconButton} disabled={disabled}>
          <CloseIcon data-testid="CancelIcon" className={defaultClasses.closeIcon} />
        </IconButton>
      }
      onDelete={() => {
        removeOption(value.id);
      }}
      ref={(el) => setTagRef(index, el)}
      {...getTagProps({ index })}
      style={{ flexShrink: 1 }}
    />
  );
};

Tag.propTypes = {
  value: PropTypes.object,
  index: PropTypes.number,
  getTagProps: PropTypes.func,
  removeOption: PropTypes.func,
  setTagRef: PropTypes.func,
  tagRefs: PropTypes.object,
  inputWidth: PropTypes.string,
  limitTags: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.object
};

export default Tag;
