import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  aboutUsPage: {
    padding: '64px 120px 0px 120px',
    paddingBottom: 20,
    backgroundColor: theme.palette.white,
    background: 'url(/images/covers/rectanglesAboutUs.png) center no-repeat',
    width: '100%',
    backgroundPositionY: 'calc(100% - 947px)',
    backgroundSize: '100% 22%'
  },
  container: {
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    textAlign: 'center',
    padding: '0px 282px 16px 282px',
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    fontSize: '60px',
    fontWeight: 700,
    lineHeight: '72px'
  },
  headerBold: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontSize: '60px',
    fontWeight: 800,
    lineHeight: '72px',
    color: theme.palette.primary.light
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '64px'
  },
  badgeWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    marginBottom: '32px'
  },
  badgeHeader: {
    gridColumn: 2,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: ' #A40606',
    width: 'fit-content',
    padding: '8px 16px',
    gap: '10px',
    borderRadius: '20px',
    backgroundColor: '#F7F6F6'
  },
  editButton: {
    padding: '8px 24px',
    borderRadius: '4px',
    gridColumn: 3,
    justifySelf: 'end',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    width: '77px'
  },
  addButton: {
    padding: '12px 24px',
    borderRadius: '4px',
    gridColumn: 3,
    justifySelf: 'end',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
  }
}));
