import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import useStyles from './styles';
import CustomTooltip from '../CustomTooltip';

const FileName = ({ imageKey, fileName, handleDeleteFile }) => {
  const classes = useStyles();

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const fileNameRef = useRef(null);

  const extractedFilename = useMemo(() => {
    const match = imageKey && imageKey.match(/[^/]+[^_]+_(.*)$/);
    return match ? match[1] : '';
  }, [imageKey]);

  const fileNameDisplayed = !!imageKey ? extractedFilename : fileName;

  useEffect(() => {
    if (fileNameRef.current) {
      setIsEllipsisActive(fileNameRef.current.scrollWidth > fileNameRef.current.clientWidth);
    }
  }, [fileName]);

  return (
    <Grid className={classes.fileNameGroup}>
      <CustomTooltip title={isEllipsisActive ? fileNameDisplayed : ''} placement="bottom-start">
        <Typography variant="newBody" className={classes.fileName} ref={fileNameRef}>
          {fileNameDisplayed}
        </Typography>
      </CustomTooltip>
      <IconButton onClick={() => handleDeleteFile()}>
        <CloseIcon className={classes.deleteFileIcon} />
      </IconButton>
    </Grid>
  );
};

FileName.propTypes = {
  imageKey: PropTypes.string,
  fileName: PropTypes.string,
  handleDeleteFile: PropTypes.func
};

export default FileName;
