import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import useStyles from './styles';
import { getPath } from '../utils';

const MainTab = ({ open, icon, tabName, tabLink, handleClick, subTabs }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid>
      <NavLink to={tabLink}>
        <Grid
          onClick={handleClick}
          className={clsx(
            open ? classes.openTabWrapper : classes.tabWrapper,
            getPath(location, tabLink) ? classes.tabWrapperActive : classes.tabWrapperNormal,
            !open && getPath(location, tabLink) && classes.closedTabWrapperActive
          )}
        >
          <Grid className={open ? classes.icon : classes.collapsedIcon}>{icon}</Grid>
          <Typography
            className={clsx(
              classes.tabName,
              getPath(location, tabLink) ? classes.tabNameActive : classes.tabNameNormal
            )}
          >
            {tabName}
          </Typography>
        </Grid>
      </NavLink>
      {subTabs && (
        <Collapse in={open && location.pathname.startsWith(tabLink)} timeout="auto" unmountOnExit>
          {subTabs.map((subTab) => (
            <Grid className={classes.subTabsWrapper}>
              <NavLink to={subTab.link}>
                <Typography
                  className={clsx(
                    classes.tabName,
                    getPath(location, subTab.link) ? classes.tabNameActive : classes.tabNameNormal
                  )}
                >
                  {subTab.name}
                </Typography>
              </NavLink>
            </Grid>
          ))}
        </Collapse>
      )}
    </Grid>
  );
};

MainTab.propTypes = {
  icon: PropTypes.object,
  tabName: PropTypes.string,
  tabLink: PropTypes.string,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  subTabs: PropTypes.array
};

export default MainTab;
