import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '41px',
    width: '100%',
    maxWidth: '850px',
    padding: '24px 24px',
    border: '1px solid',
    borderColor: '#D8DBE0',
    backgroundColor: 'white',
    borderRadius: '16px'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    color: '#111827',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px'
  },
  content: {
    width: '100%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    gap: '30px'
  },
  addButton: {
    padding: '12px 24px',
    backgroundColor: theme.palette.primary.light,
    textTransform: 'none',
    borderRadius: '4px',
    height: '48px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
  },
  cancelButton: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    backgroundColor: 'white',
    color: '#4B5563',
    width: '49px',
    textTransform: 'none',
    borderRadius: '4px',
    height: '48px',
    '&:hover': {
      backgroundColor: '#E5E7EB'
    }
  }
}));
