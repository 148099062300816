import { v4 as uuid } from 'uuid';
import mock from 'src/utils/mock';
import moment from 'moment';

const topic = {
  sorting: 'manager: ASC',
  itemsPerPage: 50,
  itemCounter: 3,
  pageCounter: 1,
  records: [
    {
      cloId: 3492,
      dealName: 'Monroe Capital MML CLO XIV Draft',
      manager: 'Manager X',
      currency: 'GBP',
      dealType: 'CBO',
      cloStatus: 'Active',
      latestMajorDocDate: '2023-02-02T15:41:00',
      latestMinorDocDate: '2022-11-24T17:24:42',
      closingDate: '2022-11-02T15:41:00',
      latestMajorDoc: null,
      latestMinorDoc: 'aaaaaa',
      nonCallPeriod: null,
      callDate: null,
      reinvestmentPeriod: '222',
      reinvestmentEndDate: '2022-11-30T15:52:00',
      otherInfo: null,
      documentId: 4515,
      esgScore: 3446.0,
      documentName: 'KKR CLO 38 - Indenture22222',
      documentExecutionDate: '2022-11-02T13:41:00',
      dscore: 753.08,
      answers: [
        {
          topicId: 23805,
          documentId: 2525,
          topicName: 'Concentration limits',
          text: 'new answer'
        },
        {
          topicId: 23806,
          documentId: 2525,
          topicName: 'Interest proceeds definition',
          text: 'new answer222'
        }
      ],
      notes: [
        {
          topicId: 23805,
          documentId: 2525,
          topicName: 'Concentration limits',
          text: 'new note222'
        },
        {
          topicId: 23806,
          documentId: 2525,
          topicName: 'Interest proceeds definition',
          text: 'new note222888'
        }
      ]
    },
    {
      cloId: 3484,
      dealName: 'Only 1 cur Minor DOC Trinitas Euro CLO III offering',
      manager: 'Manager X II',
      currency: 'GBP',
      dealType: 'CBO',
      cloStatus: 'Active',
      latestMajorDocDate: '2022-11-24T11:41:00',
      latestMinorDocDate: null,
      closingDate: '2022-11-02T12:02:03',
      latestMajorDoc: 'closing',
      latestMinorDoc: null,
      nonCallPeriod: '',
      callDate: '2024-05-02T12:01:00',
      reinvestmentPeriod: '4.5',
      reinvestmentEndDate: '2027-05-03T12:01:00',
      otherInfo: null,
      documentId: 4504,
      esgScore: 1630.0,
      documentName: 'Trinitas Euro CLO III offering',
      documentExecutionDate: '2022-12-07T11:40:00',
      dscore: 4.33,
      answers: [
        {
          topicId: 23805,
          documentId: 2526,
          topicName: 'Concentration limits',
          text: 'new answer'
        },
        {
          topicId: 23806,
          documentId: 2526,
          isUnusual: false,
          topicName: 'Interest proceeds definition',
          text: 'new answer222'
        }
      ],
      notes: [
        {
          topicId: 23805,
          documentId: 2526,
          topicName: 'Concentration limits',
          text: 'new note333333'
        },
        {
          topicId: 23806,
          documentId: 2526,
          topicName: 'Interest proceeds definition',
          text: 'new note333'
        }
      ]
    },
    {
      cloId: 482,
      dealName: 'Dryden 63 GBP CLO 2018',
      manager: 'PGIM',
      currency: 'GBP',
      dealType: 'BSL',
      cloStatus: 'Expired',
      latestMajorDocDate: '2018-09-20T13:17:00',
      latestMinorDocDate: null,
      closingDate: '2018-09-20T13:17:00',
      latestMajorDoc: 'closing',
      latestMinorDoc: '',
      nonCallPeriod: '2',
      callDate: '2020-09-21T13:17:00',
      reinvestmentPeriod: '4',
      reinvestmentEndDate: '2022-10-17T13:17:00',
      otherInfo: '',
      documentId: 482,
      esgScore: 0.0,
      documentName: 'Dryden 63 GBP CLO 2018 offering',
      documentExecutionDate: '2018-09-20T13:17:00',
      dscore: null,
      answers: [
        {
          topicId: 23805,
          documentId: 2527,
          topicName: 'Concentration limits',
          text: 'new answer'
        },
        {
          topicId: 23806,
          documentId: 2527,
          topicName: 'Interest proceeds definition',
          text: 'new answe444'
        }
      ],
      notes: [
        {
          topicId: 23805,
          documentId: 2527,
          topicName: 'Concentration limits',
          text: 'new note6767'
        },
        {
          topicId: 23806,
          documentId: 2527,
          topicName: 'Interest proceeds definition',
          text: 'new note6767'
        }
      ]
    }
  ],
  filterUniqueValues: {
    managerList: ['Manager X', 'Manager X II', 'PGIM'],
    dealNameList: [
      'Dryden 63 GBP CLO 2018',
      'Monroe Capital MML CLO XIV Draft',
      'Only 1 cur Minor DOC Trinitas Euro CLO III offering'
    ],
    documentNameList: [
      'Dryden 63 GBP CLO 2018 offering',
      'KKR CLO 38 - Indenture22222',
      'Trinitas Euro CLO III offering'
    ],
    answerList: [
      'class A-1; class A-2; class B-1; class B-2; class C-1; class C-2; class D; class E; class F; subordinated notes',
      'class A; class B-1; class B-2; class C; class D; class E; class F; subordinated notes',
      'Draft'
    ],
    notesList: ['class F notes are delayed issue'],
    currencyList: ['GBP'],
    dealTypeList: ['BSL', 'CBO'],
    cloStatusList: ['Active', 'Expired'],
    reinvestmentPeriodList: ['222', '4', '4.5'],
    latestMajorDocList: ['closing'],
    latestMinorDocList: ['aaaaaa'],
    nonCallPeriodList: ['2'],
    otherInfoList: []
  }
};

mock.onGet('/api/operations/topic-management').reply(200, {
  topics: [
    {
      id: uuid(),
      category: 'Balances',
      created: moment(),
      question: 'What value is used for assets defaulted for three years or more in the CPA?',
      answer: 'during reinvestment period',
      status: 'Pending',
      topicName: 'CPA 3Y defaulted treatment',
      updated: moment()
    },
    {
      id: uuid(),
      category: 'A Category',
      created: moment(),
      question: 'What % of collateral can be second lien and unsecured?',
      answer: 'market value',
      status: 'Inactive',
      topicName: 'CPA 3Y defaulted treatment',
      updated: moment()
    }
  ]
});
