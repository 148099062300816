import React from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Button, Grid } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';

const ElasticSearchButton = ({ open, onClick, active }) => {
  const classes = useStyles();

  return (
    <Grid className={clsx(classes.buttonWrapper, !open && classes.closedButtonWrapper)}>
      <Button
        classes={{ root: classes.root }}
        style={{
          border: open ? '2px solid' : '1px solid',
          borderRadius: '12px'
        }}
        sx={{
          '& .MuiButton-startIcon': { marginLeft: '0px' }
        }}
        className={clsx(
          classes.button,
          !open && classes.closedButton,
          active && classes.activeButton
        )}
        variant="outlined"
        onClick={onClick}
        startIcon={<SearchRoundedIcon fontSize="small" />}
      >
        <span className={clsx(classes.buttonTitle, !open && classes.hiddenTitle)}>
          Search Dealscribe
        </span>
      </Button>
    </Grid>
  );
};

ElasticSearchButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

export default ElasticSearchButton;
