import {
  ADMIN_ROLE,
  EDITOR_ROLE,
  TRIAL_USER_ROLE,
  USER_ROLE,
  VIEWER_USER_ROLE
} from 'src/utils/constants/stringConstants';

export const adminTabsOperations = [
  { link: '/operations/user-management', name: 'Users' },
  { link: '/operations/topic-management', name: 'Topics' },
  { link: '/operations/category-management', name: 'Categories' },
  { link: '/operations/private-content', name: 'Private Content' },
  { link: '/operations/analytics', name: 'Analytics' },
  { link: '/operations/workflow', name: 'Workflow' },
  { link: '/operations/products', name: 'Products' },
  { link: '/operations/deal-types', name: 'Deal Types' }
];

export const editorTabsOperations = [
  { link: '/operations/user-management', name: 'Users' },
  { link: '/operations/workflow', name: 'Workflow' }
];

export const viewerUserTabsDeals = [{ name: 'Deals', link: '/deal/page/deals' }];

export const trialUserTabsDeals = [
  ...viewerUserTabsDeals,
  { name: 'Portfolios', link: '/deal/page/portfolios' },
  { name: 'Comparison', link: '/deal/page/comparison' }
];

export const commonTabsDeals = [
  ...trialUserTabsDeals,
  { name: 'Track changes', link: '/deal/page/track-changes' }
];

export const getUserTabDeals = (userRole) => {
  switch (userRole) {
    case ADMIN_ROLE:
    case EDITOR_ROLE:
    case USER_ROLE:
      return commonTabsDeals;
    case TRIAL_USER_ROLE:
      return trialUserTabsDeals;
    case VIEWER_USER_ROLE:
      return viewerUserTabsDeals;

    default:
      return commonTabsDeals;
  }
};

export const getOperationsTabs = (userRole) =>
  userRole === ADMIN_ROLE ? adminTabsOperations : editorTabsOperations;

const adminTab = (userRole) => [
  { name: 'Home', link: '/home' },
  { name: 'Operations', link: '/operations', subTabs: getOperationsTabs(userRole) },
  { name: 'Deals', link: '/deal/page', subTabs: getUserTabDeals(userRole) },
  { name: 'Reports', link: '/reports' },
  { name: 'About us', link: '/about-us' }
];

const editorTab = (userRole) => [
  { name: 'Home', link: '/home' },
  { name: 'Operations', link: '/operations', subTabs: getOperationsTabs(userRole) },
  { name: 'Deals', link: '/deal/page', subTabs: getUserTabDeals(userRole) },
  { name: 'Reports', link: '/reports' },
  { name: 'About us', link: '/about-us' }
];

const userTab = (userRole) => [
  { name: 'Home', link: '/home' },
  { name: 'Deals', link: '/deal/page', subTabs: getUserTabDeals(userRole) },
  { name: 'Reports', link: '/reports' },
  { name: 'About us', link: '/about-us' }
];

export const getUserTab = (userRole) => {
  switch (userRole) {
    case ADMIN_ROLE:
      return adminTab(userRole);
    case EDITOR_ROLE:
      return editorTab(userRole);
    case TRIAL_USER_ROLE:
    case USER_ROLE:
    case VIEWER_USER_ROLE:
      return userTab(userRole);

    default:
      return [];
  }
};
