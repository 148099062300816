import axios from 'axios';

export const getAboutUsDescription = async () => {
  const { data } = await axios.get('/about-us');
  return data;
};

export const putAboutUsDescription = async (text) => {
  await axios.put('/about-us', { text });
};
