import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  containerWidth408: {
    width: 408
  },
  containerFitContentWidth: {
    width: 'fit-content'
  },
  containerConfirmDeletionContent: {
    height: 144
  },
  containerDealFileDropzone: {
    width: 1280
  },
  containerReportFileDropzone: {
    width: 1280
  },
  actionsRightPosition: {
    justifyContent: 'flex-end'
  },
  actions: {
    justifyContent: 'space-between'
  },
  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cross: {
    cursor: 'pointer',
    '&:hover': {
      color: '#546E7A'
    }
  },
  deleteButton: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`
  },
  apiKeyItem: {
    marginLeft: 'auto'
  },
  generateButton: {
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.text.secondary}`
  },
  passwordButton: {
    height: '38px',
    marginRight: '16px'
  },
  margin: {
    marginTop: '16px'
  },
  label: {
    color: '#80868B'
  },
  text: {
    color: '#263238',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '-0.05px'
  },
  padding: {
    padding: '0 0 6px 0'
  },
  buttonConfirmation: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  closeButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark
    }
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'underline'
  },
  closeIcon: {
    width: '15px',
    height: '15px',
    color: '#000000 !important'
  }
}));

export default useStyles;
